@import '../../scss/size.scss';

.table-striped-rows tr {
  display: flex;
  flex-direction: row;
}

.table-striped-rows tr td:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: hs(140px);
  border-right: hs(1px) solid #7da1b3 !important;
  padding: 2px 0 2px 2px !important;
  font-weight: bold !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: hs(8px) 0 hs(15px) hs(-8px) rgba(26, 201, 232, 0.75);
  background-color: white !important;
}

.table-striped-rows td {
  display: flex;
  flex-direction: row;
  width: hs(216px);
  border-right: hs(1px) solid #7da1b3 !important;
  padding: 0px !important;
  height: hs(40px);
  &:hover {
    height: hs(40px);
  }
}


.table-striped-rows th {
  width: hs(216px);
  border-right: 1px solid #7da1b3 !important;
  display: flex;
  font-weight: bold !important;
  background-color: #eae6e6 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  padding: 0 !important;
  height: hs(80px) !important;
}

.table-striped-rows tr th:nth-child(1) {
  width: hs(140px);
  border-right: hs(1px) solid #7da1b3 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: 0 hs(5px) hs(10px) 0 rgba(26, 201, 232, 0.75);
  background-color: #eae6e6;
}

.table-striped-rows {
  border: hs(1px) solid #7da1b3 !important;
  border-radius: hs(4px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  & .ant-table-content{
    width: hs(1655px) !important;
  }
}

.table-striped-rows tr:nth-child(16) {
  background-color: rgba(236, 219, 219, 0.4) !important;
  font-weight: bold !important;
}

.table-striped-rows tr:nth-child(16) td:nth-child(1) {
  background-color: #ecdbdb !important;
}


.table-striped-rows tr td:nth-child(2n) {
  background-color: rgba(211,211,211, 0.2) !important;
}

.table-striped-rows tr:last-child td:nth-child(n + 1) {
  display: flex;
  height: hs(204px);
  color: #565050 !important;
  flex-direction: column !important;
}

//Styles for report table

.report-table tr {
  display: flex;
  flex-direction: row;
}

.report-table tr td:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: hs(340px);
  border-right: hs(1px) solid #7da1b3 !important;
  padding: 2px 0 2px 2px !important;
  font-weight: bold !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: hs(8px) 0 hs(15px) hs(-8px) rgba(26, 201, 232, 0.75);
  background-color: white !important;
}

.report-table td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: hs(22px);
  width: hs(200px);
  border-right: hs(1px) solid #7da1b3 !important;
  padding: 0px !important;
  height: hs(50px) !important;
  &:hover {
    height: hs(50px) !important;
  }
}

.report-table th {
  width: hs(200px);
  border-right: 1px solid #7da1b3 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  background-color: #eae6e6 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  padding: 0 !important;
  height: hs(50px) !important;
}

.report-table tr td:nth-child(1) {
  font-size: hs(19px) !important;
}
.report-table tr th:nth-child(1) {
  width: hs(340px);
  border-right: hs(1px) solid #7da1b3 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: 0 hs(5px) hs(10px) 0 rgba(26, 201, 232, 0.75);
  background-color: #eae6e6;
}

.report-table {
  border: hs(1px) solid #7da1b3 !important;
  border-radius: hs(4px);
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: hs(1740px) !important;
}

.report-table tr td:nth-child(2n) {
  background-color: rgba(211,211,211, 0.2) !important;
}

.report-table tr:last-child td:nth-child(n + 1) {
  display: flex;
  height: hs(204px);
  color: #565050 !important;
  flex-direction: column !important;
}

.currDateCell {
  width: 100%;
  background-color: rgba(193,235,221, 0.8);
  height: 100%;
  border-top: 1px #fff solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
