@import '../../../scss/size.scss';


.studentTabTable tr td:nth-child(1) {
    font-size: hs(20px);
    font-weight: bold;
    width: 30%;
}
.studentTabTable tr td:nth-child(2) {
    font-size: hs(20px);
    font-weight: bold;
}
.studentTabTable tr td:nth-child(3) {
    font-size: hs(18px);
    font-weight: 400 !important;
}
.studentTabTable tr td:nth-child(4) {
    font-size: hs(18px);
    font-weight: 400 !important;
}
.studentTabTable tr td:nth-child(5) {
    font-size: hs(18px);
    font-weight: 400 !important;
}
.studentTabTable tr td:nth-child(6) {
    font-size: hs(18px);
    font-weight: 400 !important;
}

.studentTabTable th {
    color: #212121 !important;
    font-weight: bold !important;
    background-color: #F5F5F5 !important;
    font-size: hs(18px);
  }

  .studentTabTable{
      padding-left: 20px;
      padding-right: 20px;
  }