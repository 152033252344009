// Horizontal Scale Factor
@function hsf($width, $factor) {
  @return ($width / 1920px) * 100vw;
}

// Horizontal Scale
@function hs($width) {
  @return hsf($width, 1920px)
}

