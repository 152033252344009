h1{
    margin-bottom: 0;
}
.speakerForm{
    // width: 100%;
    // padding: 0.5rem 0 0.5rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.addSpeakerProfileForm{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0.5rem 0 0.5rem 2rem;
    // padding: 0.5rem auto;
}
.addSpeakerProfileForm > .ant-form-item{
    background-color: red;
}
.ant-form-item-control {
    line-height: 8px;
}
.ant-form-item-label{
    background-color: pink;
    text-align: left;
    line-height: normal;
}
.speakerDetail:nth-child(2n){
    margin-left: 1rem;
}
.titleContainer{
    margin-top: 1.5rem;
    display: flex;
    align-items: flex-start;
    width:41vw
}

.soundIcon{
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 0.75rem;
}

.prizeForm{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0 0.5rem 2rem;
    flex-basis: 60%;
}
.prizeImage{
    margin: 12px 10px;
     width: 210px;
}
.addSpeakerImageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addBtnContainer{
    margin:1rem 1rem 1rem 2rem;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     width: 39vw;
 }
.removeForm{
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    padding: 0.35rem;
    &:hover{
        background-color: rgb(201, 201, 201);
        transition:200ms ease;
    }
}
.prizeNameWrapper{
    border: 1px solid rgb(201, 201, 201);
    border-radius: 0.65rem;
    padding: 0 0.5rem;
}
.speaker-form-number{
    margin-right: 1rem;
}
