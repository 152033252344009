@import '../../scss/size.scss';

.profileContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Nunito;
  justify-content: center;
}

.profileTopContainer {
  display: flex;
  flex-direction: row;
  width: 97%;
  justify-content: flex-end;
}

.profileCountContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: hs(50px);
  background-color: rgba(255, 216, 232, 0.5);
  color: rgba(0, 0, 0, 0.6);
  padding: 0 8px 0 8px;
  font-size: hs(20px);
  border-radius: 4px;
}

.mentorSkillDateCell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mentorName {
  display: flex;
  justify-content: center;
}

.parentCell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.skillLevel {
  display: flex;
  justify-content: center;
  // width: 9vw;
}

.date {
  display: flex;
  justify-content: center;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  padding-right: 10px;
}

.time {
  display: flex;
  justify-content: center;
}

.skillTypeRadioGrp {
  display: flex;
  flex-direction: row;
}

.skillTypeRadioBtn {
  width: hs(130px);
  display: flex;
  justify-content: center;
}

.profileTablePagination {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

.bookSessionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.4vw;
  border-radius: 6px;
  font-size: hs(19px);
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(193,234,234, 0.8);
  cursor: pointer;
  padding: 15px;
  cursor: pointer;
  width: fit-content;
  &:active {
    font-size: hs(18px);
  }
}
