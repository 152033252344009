h3,p,h5{
    margin: 0%;
}
.userImage{
    object-fit: cover;
    width: 32px;
    height: 32px;
    border-radius: 5px;
}
.RatingWrapper{
    display: flex;
    align-items: center;
}
.leftbarWrapper{
    height: 50%;
    width: 100%;
    background-color: white;
    padding: 1%;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;    
}
.leftbarTopContainer{
    display: flex;
    align-items: center;
    width: calc(100% - 2%);
    justify-content: space-between;
}
.leftbarLogoImage{
    cursor: pointer;
}
.logoContainer{
    background-color: #f5f5f5;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
}
.leftbarContentContainer{
    width: calc(100% - 2%);
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    padding-top: 5%;
    padding-bottom: 5%;
    border-radius: 8px;
}
.DetailsContent{
    display: flex;
}
.currentUserName{
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    width: 100%;
}
.nameAnRatingWrapper{
    margin-left: 6px;
    width: 100%;
}
.profileChangerBtn{
    position: relative;
    margin-right: 5%;
    width: 2px;
}
.leftbarlistItem{
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 3%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    height: 30%;
    padding-top: 7%;
    padding-bottom: 7%;
}
.leftbarlistItem:hover{
    background-color: #E6F7F5;
}
.leftbarlistItem:hover h5{
    color: #01AA93;
}

.leftbarOptions{
    list-style-type: none;
    color: #212121;
    width: 100%;
    padding-left: 0%;
    font-size: 18px;
}
.optionName{
    margin-left: 5%;
    transition: all 0.3s ease-in-out;
}

.profileChangerBtn{
    position: relative;
    height: 100%;
}

.previousButton{
    font-size:10px;
    position: absolute;
    bottom: 50%;
    right: 5%;
    cursor: pointer;
    color: #aaa9a9;
}
.nextButton{
    font-size: 10px;
    position: absolute;
    bottom: 20%;
    right: 5%;
    cursor: pointer;
    color: #aaa9a9;
    transform: rotateX(180deg);
}
.rating{
    margin-left: 4px;
    font-size: 10px;
}
.notificationBadge{
    position: absolute;
    height: 13px;
    width: 13px;
    top: 0%;
    transform: translateY(-50%);
    right: 0;
    transform: translateX(-10%);
    border-radius: 50%;
    background-color: #D34B57;
    color: white;
}
.numberOfNotificationText{
    font-Size:8px;
    letter-spacing: -0.2px;
    text-align: center;
    transform: translateX(-7%);
}
.mailLogo{
    min-width: 15px;
    min-height: 15px;
}