.downloadReportBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    outline: none;
    background-color: #01AA93;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 8px;
    margin-left: auto;
    cursor: pointer;
}
.paymentsTopSectionWrapper{
    display: flex;
    align-items: center;
}
.paymentsTopSectionImgContainer{
    background-color: #eeeeee;
    padding: 4px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 5px;
}
.paymentsSectionSeperator{
    border: 2px solid #eeeeee;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.paymentActionsContainer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.paymentsSectionTable td{
    color: #212121;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    padding-block: 15px;
}
.paymentsSectionTable th{
    color: #212121;
    text-align: center;
}
.paymentStatusContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.paymentStatusContainer p{
    color: #7D3F3A;
    font-size: 10px;
    text-transform: capitalize;
    margin-left: 4px;
}
.paymentsSectionContainer{
    width: 100%;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    margin-top: 10px;
}
.paymentsTopSectionWrapper{
    display: flex;
    width: 100%;
    align-items: center;
}
.paymentsSectionTable{
    width: 100%;
}
.installmentProgressBar{
    height: 8px;
    background-color: #eeeeee;
    border-radius: 25px;
    width: 100%;
    position: relative;
    margin-top: 2vh;
    margin-bottom: 3vh;
}

.installmentProgressBarInner{
    position: absolute;
    height: 100%;
    background-color: #01AA93;
    border-radius: 25px;    
}
.paymentsTopSectionTextContainer{
    margin-left: 15px;
}
.installmentCompleteText{
    color: #333333;
}
@media screen and (max-width:961px){
    .paymentsSectionTable td, .paymentsSectionTable th{
        padding: 8px;
    }
    .paymentsSectionTable td{
        padding: 8px;
        font-size:12px
    }
    .downloadReportBtn{
        font-size: 12px;
    }
}
@media screen and (max-width:873px){
    .paymentsSectionTable td{
        padding-left: 2px;
        padding-right: 2px;
        font-size:10px;
    }
}
@media screen and (max-width:738px){
    .paymentsTopSectionTextContainer{
        width: 70%;
    }
    .paymentsTopSectionWrapper{
        display: flex;
        flex-wrap: wrap;
    }
}