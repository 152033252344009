@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
.ant-progress-success-bg
{
    background-color: #80D3BB;
    
}
.ant-progress-inner{
  border-radius:0;
}

.ImageContainer{
    /* margin-inline: auto; */
    width: 100%;
  }
  .imageSelectorWrapper{
      display: flex;
      justify-content: center;
      margin-inline: auto;
  }
  .imageLabelNumber{
      margin: 0 !important;
      text-align: center;
      margin-bottom: 6px;
  }
  .imageSelectorBtn{
      margin-right: 5px;
      cursor: pointer;
      opacity: 0.75;
  }
  .removeOptionBtn{
      margin-left: 5px;
  }
  .modifiedImageSelectorBtn{
    margin-right: 15px;
    cursor: pointer;
    opacity: 0.75;
  }
  .uploadbtnContainer{
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .imageUploadedPopup{
      position: fixed;
      right: 1%;
      top: 0%;
      width: 200px;
      height: 60px;
      background: white;
      align-items: center;
      display: flex;
      justify-content: center;
      border-bottom: 3px solid #52c41a;
      transition: all 0.3s ease-in-out;
  }
  .imageUploadedPopup p, .hiddenPopup p{
      margin: 0%;
      margin-right: 10px;
  }
  .hiddenPopup{
    position: fixed;
    right: 0%;
    display: flex;
    justify-content: center;
    top: 0%;
    width: 200px;
    align-items: center;
    height: 60px;
    background: #ccc;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }  
.inputContainer > textarea {
  border: none;
  position: relative;
  border-bottom: 2px solid #c7c5b9; }

.inputContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(100%); }

.previewComponent {
  position: 'relative';
  max-height: 150px;
  width: 100%;
  overflow-y: scroll;
  background: inherit; }
  .previewComponent .tox-tinymce {
    box-shadow: none;
    border: none;
    background-color: transparent; }
  .previewComponent iframe {
    background: inherit !important;
    background-color: inherit !important; }
  .previewComponent::-webkit-scrollbar {
    width: 4px;
    background-color: transparent; }
  .previewComponent::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #00ADE6; }

.switch-editor-btn {
  position: absolute;
  top: 6px;
  right: 0;
  cursor: pointer;
  z-index: 99;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  transition: all .5s;
  transform: scale(0.9);
  background-color: #EEE; }
  .switch-editor-btn:hover {
    transform: scale(1);
    background-color: #DDD; }
  .switch-editor-btn:active {
    transform: scale(0.8);
    background-color: #DDD; }

.mentor-sessions-table {
  display: flex;
  flex-direction: row;
  font-family: Nunito;
  position: relative;
  overflow-x: hidden; }

.mentor-sessions-table tr {
  display: flex; }

.mentor-sessions-table td {
  display: flex; }

.mentor-sessions-table th {
  display: flex;
  padding: 0.41667vw 0 0.41667vw 0 !important;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: bold !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  font-size: 0.9375vw; }

.mentor-sessions-table tr td {
  display: flex;
  padding: 0.72917vw 0 0.72917vw 0 !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: 0.88542vw; }

.date-row td:nth-child(1) {
  width: 90vw !important;
  border-right: none !important;
  height: 2.91667vw !important;
  background-color: rgba(0, 0, 0, 0.035);
  font-weight: bold;
  font-size: 0.83333vw !important;
  overflow: hidden; }

.date-row-viewer td:nth-child(1) {
  width: 82vw !important;
  border-right: none !important;
  height: 2.91667vw !important;
  background-color: rgba(0, 0, 0, 0.035);
  font-weight: bold;
  font-size: 0.83333vw !important;
  overflow: hidden; }

.mentor-sessions-table tr td:nth-child(1), .mentor-sessions-table th:nth-child(1) {
  width: 5vw; }

.mentor-sessions-table tr td:nth-child(2), .mentor-sessions-table th:nth-child(2) {
  width: 16vw; }

.mentor-sessions-table tr td:nth-child(3), .mentor-sessions-table th:nth-child(3) {
  width: 35vw;
  padding-bottom: 0 !important; }

.mentor-sessions-table tr td:nth-child(4), .mentor-sessions-table tr td:nth-child(5),
.mentor-sessions-table th:nth-child(4), .mentor-sessions-table th:nth-child(5) {
  width: 13vw; }

.mentor-sessions-table tr td:nth-child(6), .mentor-sessions-table th:nth-child(6) {
  width: 8vw; }

.timeIcon {
  display: flex;
  position: relative;
  right: 0;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%; }

.hoverable:hover {
  cursor: pointer; }

.sessionTypeRadioGrp {
  display: flex;
  flex-direction: row; }

.sessionTypeRadioBtn {
  width: 6.77083vw;
  display: flex;
  justify-content: center; }

.icon_icon__-JPPE {
  height: 2.29167vw;
  width: 2.29167vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .icon_icon__-JPPE:hover {
    opacity: 0.7; }

.icon_completedIcon__2rXQ9 {
  background-image: url(/static/media/completIcon.8aa6a300.svg); }

.icon_notCompletedIcon__FFDWM {
  background-image: url(/static/media/incompletIcon.fb6a1fb6.svg); }

.icon_videoLinkYes__oAIHj {
  background-image: url(/static/media/connectionYes.5cea3370.svg); }

.icon_videoLinkNo__3wp-0 {
  background-image: url(/static/media/connectionNo.c9efcd62.svg); }

.icon_feedbackYes__2Mkuq {
  background-image: url(/static/media/feedbackYes.33e27149.svg);
  filter: brightness(1.4); }

.icon_feedbackNo__lxNgN {
  background-image: url(/static/media/feedbackNo.222262d7.svg); }

.table-striped-rows tr {
  display: flex;
  flex-direction: row; }

.table-striped-rows tr td:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 7.29167vw;
  border-right: 0.05208vw solid #7da1b3 !important;
  padding: 2px 0 2px 2px !important;
  font-weight: bold !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: 0.41667vw 0 0.78125vw -0.41667vw rgba(26, 201, 232, 0.75);
  background-color: white !important; }

.table-striped-rows td {
  display: flex;
  flex-direction: row;
  width: 11.25vw;
  border-right: 0.05208vw solid #7da1b3 !important;
  padding: 0px !important;
  height: 2.08333vw; }
  .table-striped-rows td:hover {
    height: 2.08333vw; }

.table-striped-rows th {
  width: 11.25vw;
  border-right: 1px solid #7da1b3 !important;
  display: flex;
  font-weight: bold !important;
  background-color: #eae6e6 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  padding: 0 !important;
  height: 4.16667vw !important; }

.table-striped-rows tr th:nth-child(1) {
  width: 7.29167vw;
  border-right: 0.05208vw solid #7da1b3 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: 0 0.26042vw 0.52083vw 0 rgba(26, 201, 232, 0.75);
  background-color: #eae6e6; }

.table-striped-rows {
  border: 0.05208vw solid #7da1b3 !important;
  border-radius: 0.20833vw;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden; }
  .table-striped-rows .ant-table-content {
    width: 86.19792vw !important; }

.table-striped-rows tr:nth-child(16) {
  background-color: rgba(236, 219, 219, 0.4) !important;
  font-weight: bold !important; }

.table-striped-rows tr:nth-child(16) td:nth-child(1) {
  background-color: #ecdbdb !important; }

.table-striped-rows tr td:nth-child(2n) {
  background-color: rgba(211, 211, 211, 0.2) !important; }

.table-striped-rows tr:last-child td:nth-child(n + 1) {
  display: flex;
  height: 10.625vw;
  color: #565050 !important;
  flex-direction: column !important; }

.report-table tr {
  display: flex;
  flex-direction: row; }

.report-table tr td:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 17.70833vw;
  border-right: 0.05208vw solid #7da1b3 !important;
  padding: 2px 0 2px 2px !important;
  font-weight: bold !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: 0.41667vw 0 0.78125vw -0.41667vw rgba(26, 201, 232, 0.75);
  background-color: white !important; }

.report-table td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.14583vw;
  width: 10.41667vw;
  border-right: 0.05208vw solid #7da1b3 !important;
  padding: 0px !important;
  height: 2.60417vw !important; }
  .report-table td:hover {
    height: 2.60417vw !important; }

.report-table th {
  width: 10.41667vw;
  border-right: 1px solid #7da1b3 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  background-color: #eae6e6 !important;
  color: rgba(0, 0, 0, 0.65) !important;
  padding: 0 !important;
  height: 2.60417vw !important; }

.report-table tr td:nth-child(1) {
  font-size: 0.98958vw !important; }

.report-table tr th:nth-child(1) {
  width: 17.70833vw;
  border-right: 0.05208vw solid #7da1b3 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  box-shadow: 0 0.26042vw 0.52083vw 0 rgba(26, 201, 232, 0.75);
  background-color: #eae6e6; }

.report-table {
  border: 0.05208vw solid #7da1b3 !important;
  border-radius: 0.20833vw;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 90.625vw !important; }

.report-table tr td:nth-child(2n) {
  background-color: rgba(211, 211, 211, 0.2) !important; }

.report-table tr:last-child td:nth-child(n + 1) {
  display: flex;
  height: 10.625vw;
  color: #565050 !important;
  flex-direction: column !important; }

.currDateCell {
  width: 100%;
  background-color: rgba(193, 235, 221, 0.8);
  height: 100%;
  border-top: 1px #fff solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.reportTable tr td:nth-child(1) {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  font-weight: bold; }

.reportTable tr th:nth-child(1) {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  font-weight: bold; }

.reportTable {
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden; }

.profileContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Nunito;
  justify-content: center; }

.profileTopContainer {
  display: flex;
  flex-direction: row;
  width: 97%;
  justify-content: flex-end; }

.profileCountContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.60417vw;
  background-color: rgba(255, 216, 232, 0.5);
  color: rgba(0, 0, 0, 0.6);
  padding: 0 8px 0 8px;
  font-size: 1.04167vw;
  border-radius: 4px; }

.mentorSkillDateCell {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.mentorName {
  display: flex;
  justify-content: center; }

.parentCell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }

.skillLevel {
  display: flex;
  justify-content: center; }

.date {
  display: flex;
  justify-content: center;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  padding-right: 10px; }

.time {
  display: flex;
  justify-content: center; }

.skillTypeRadioGrp {
  display: flex;
  flex-direction: row; }

.skillTypeRadioBtn {
  width: 6.77083vw;
  display: flex;
  justify-content: center; }

.profileTablePagination {
  display: flex;
  justify-content: center;
  margin: 10px auto; }

.bookSessionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.4vw;
  border-radius: 6px;
  font-size: 0.98958vw;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(193, 234, 234, 0.8);
  cursor: pointer;
  padding: 15px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .bookSessionButton:active {
    font-size: 0.9375vw; }

.icon_icon__1Deqz {
  height: 2.29167vw;
  width: 2.29167vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .icon_icon__1Deqz:hover {
    opacity: 0.7; }

.icon_completedIcon__1W3ru {
  background-image: url(/static/media/completIcon.8aa6a300.svg); }

.icon_notCompletedIcon__3t5M1 {
  background-image: url(/static/media/incompletIcon.fb6a1fb6.svg); }

.icon_videoLinkYes__MzbzG {
  background-image: url(/static/media/connectionYes.5cea3370.svg); }

.icon_videoLinkNo__bX7s_ {
  background-image: url(/static/media/connectionNo.c9efcd62.svg); }

.icon_feedbackYes__1exbp {
  background-image: url(/static/media/feedbackYes.33e27149.svg);
  filter: brightness(1.4); }

.icon_feedbackNo__2SuWm {
  background-image: url(/static/media/feedbackNo.222262d7.svg); }

.calculator-div {
  border-radius: 5px;
  box-shadow: 0 0 12px grey;
  position: relative; }
  .calculator-div table,
  .calculator-div th,
  .calculator-div td {
    position: relative;
    padding: 4px 0;
    border-collapse: separate;
    border-spacing: 0.5em 0; }
  .calculator-div th,
  .calculator-div td {
    padding: 10px 5px;
    text-align: center; }
  .calculator-div th {
    background-color: rgba(0, 114, 255, 0.17); }
  .calculator-div td {
    background-color: rgba(184, 240, 255, 0.35); }
  .calculator-div form {
    display: flex;
    flex-direction: row;
    border: 1px solid grey;
    padding: 2px; }
  .calculator-div input {
    flex-grow: 2;
    border: none; }
  .calculator-div input:focus {
    outline: none; }
  .calculator-div input[type='number']::-webkit-inner-spin-button,
  .calculator-div input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

#editor {
  width: inherit !important;
  height: 150px !important; }

#editor1#editor1,
div.interpretor[id^="interpretor"],
div.interpretor[id^="__interpretor"] {
  background-color: transparent !important; }

.ace_hidden-cursors {
  opacity: 0; }

#__editor__full__screen .ace_editor {
  background-color: #002f3e !important;
  padding-top: 20px !important;
  box-sizing: border-box; }

#__editor__full__screen .ace_gutter {
  width: 85px !important;
  background-color: transparent !important; }

.ace-dracula .ace_gutter-active-line {
  background-color: transparent !important; }

.ace-dracula .ace_marker-layer .ace_active-line {
  background-color: transparent !important; }

.ace_scrollbar-v::-webkit-scrollbar {
  max-width: 5px; }

.ace_scrollbar-v::-webkit-scrollbar-thumb {
  background-color: rgba(52, 228, 234, 0.5);
  border-radius: 0; }

.ace_scrollbar-h::-webkit-scrollbar {
  max-height: 5px; }

.ace_scrollbar-h::-webkit-scrollbar-thumb {
  background-color: rgba(52, 228, 234, 0.5);
  border-radius: 0; }

.iCOEIC {
  background-color: inherit !important;
  min-height: inherit !important; }

.ouputBox {
  background-color: #002f3e;
  width: inherit;
  height: 150px; }

.icon_icon__323Rj {
  height: 2.29167vw;
  width: 2.29167vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .icon_icon__323Rj:hover {
    opacity: 0.7; }

.icon_completedIcon__L-E3H {
  background-image: url(/static/media/completIcon.8aa6a300.svg); }

.icon_notCompletedIcon__XbYDM {
  background-image: url(/static/media/incompletIcon.fb6a1fb6.svg); }

.icon_videoLinkYes__1r2qz {
  background-image: url(/static/media/connectionYes.5cea3370.svg); }

.icon_videoLinkNo__1UBEL {
  background-image: url(/static/media/connectionNo.c9efcd62.svg); }

.icon_feedbackYes__2Bl4R {
  background-image: url(/static/media/feedbackYes.33e27149.svg);
  filter: brightness(1.4); }

.icon_feedbackNo__2fp2w {
  background-image: url(/static/media/feedbackNo.222262d7.svg); }

.icon_icon__wGuSd {
  height: 2.29167vw;
  width: 2.29167vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .icon_icon__wGuSd:hover {
    opacity: 0.7; }

.icon_completedIcon__1VIhX {
  background-image: url(/static/media/completIcon.8aa6a300.svg); }

.icon_notCompletedIcon__25JYk {
  background-image: url(/static/media/incompletIcon.fb6a1fb6.svg); }

.icon_videoLinkYes__28k2_ {
  background-image: url(/static/media/connectionYes.5cea3370.svg); }

.icon_videoLinkNo__1VzCA {
  background-image: url(/static/media/connectionNo.c9efcd62.svg); }

.icon_feedbackYes__3frW7 {
  background-image: url(/static/media/feedbackYes.33e27149.svg);
  filter: brightness(1.4); }

.icon_feedbackNo__1h9T4 {
  background-image: url(/static/media/feedbackNo.222262d7.svg); }

:root {
  --fc-today-bg-color: rgba(0,0,0,0.023);
  --fc-border-color: #F3F3F3;
  --fc-now-indicator-color: #8C61CB; }

.mentor-dashboard-calender-loading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  padding: 12px 20px; }

.mentor-dashboard-calender-loading {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 450;
  color: #8C61CB; }

.prev-dates {
  cursor: not-allowed; }

.normal-dates {
  cursor: pointer; }

#mentor-dashboard-calender-container {
  height: 100%;
  width: 100%;
  letter-spacing: 0.4px; }
  #mentor-dashboard-calender-container a:hover {
    color: #605959; }

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease-in-out; }
  .loading-container.show {
    opacity: 0.6;
    pointer-events: initial; }

.loading-bar-container {
  width: 100%;
  background: #8C61CB;
  height: 5px; }
  .loading-bar-container div {
    height: 100%;
    width: 0%;
    position: relative;
    left: 0;
    background: white;
    animation: indeterminate-scale-animation 1.5s linear infinite; }

@keyframes indeterminate-scale-animation {
  0% {
    left: 0%;
    width: 0%; }
  20% {
    left: 0%;
    width: 30%;
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432); }
  60% {
    left: 70%;
    animation-timing-function: cubic-bezier(0.25776, 0.00316, 0.21176, 1.38179);
    width: 30%; }
  100% {
    left: 100%;
    width: 0%; } }

#mentor-dashboard-calender-container,
#demand-calendar-dashboard-container,
#demand-calendar-dashboard-container-week,
#supply-calendar-dashboard-container {
  /* Track */
  /* Handle */ }
  #mentor-dashboard-calender-container ::-webkit-scrollbar,
  #demand-calendar-dashboard-container ::-webkit-scrollbar,
  #demand-calendar-dashboard-container-week ::-webkit-scrollbar,
  #supply-calendar-dashboard-container ::-webkit-scrollbar {
    width: 4px; }
  #mentor-dashboard-calender-container ::-webkit-scrollbar-track,
  #demand-calendar-dashboard-container ::-webkit-scrollbar-track,
  #demand-calendar-dashboard-container-week ::-webkit-scrollbar-track,
  #supply-calendar-dashboard-container ::-webkit-scrollbar-track {
    width: 2px;
    border-radius: 100px; }
  #mentor-dashboard-calender-container ::-webkit-scrollbar-thumb,
  #demand-calendar-dashboard-container ::-webkit-scrollbar-thumb,
  #demand-calendar-dashboard-container-week ::-webkit-scrollbar-thumb,
  #supply-calendar-dashboard-container ::-webkit-scrollbar-thumb {
    background: #CCCCCC;
    border-radius: 100px; }

.fc-timegrid-event {
  background: none !important;
  border: none !important; }

#demand-calendar-dashboard-container .fc .fc-popover {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 90% !important; }

#demand-calendar-dashboard-container .fc-timegrid-event-harness {
  width: 100px !important; }

#demand-calendar-dashboard-container-week .fc .fc-popover {
  top: 50% !important;
  left: 50% !important;
  width: 90% !important;
  transform: translate(-50%, -50%); }

.event-container {
  width: 100%;
  height: 100%;
  letter-spacing: 0px;
  overflow: hidden;
  border-radius: 6px;
  padding: 2px 2px 2px 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  border-color: transparent;
  color: #fff;
  transition: all 0.4s ease-in; }
  .event-container > .event-details {
    display: inline-flex;
    width: 100%;
    overflow: hidden; }

.event-empty-slot {
  background: #FAF6FF !important;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .event-empty-slot::after {
    content: '+';
    width: 20px;
    color: #8C61CB;
    font-size: 30px;
    font-weight: 200;
    display: none; }
  .event-empty-slot:hover {
    box-shadow: 0px 4px 20px rgba(14, 0, 35, 0.1);
    border: 1px dashed #8C61CB; }
    .event-empty-slot:hover::after {
      display: block; }

.event-date-indicator {
  text-transform: lowercase;
  margin-right: 3px;
  letter-spacing: -.5px; }

.event-title {
  font-weight: 600;
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize; }

.event-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  position: relative;
  fill: #FFF;
  top: 2px; }
  .event-icon svg {
    margin-right: 2px; }

.event-noOfStudent-indicator {
  border-radius: 4px;
  letter-spacing: -.5px;
  padding: 2px 3px;
  min-width: 20px;
  text-align: center; }

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 12px;
  flex-wrap: wrap; }

.fc-toolbar-title {
  font-size: 18px !important;
  display: inline-block;
  vertical-align: top; }

.fc-view-harness {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  font-family: 'Inter'; }

.fc-col-header-cell-cushion, .fc-list-day-cushion {
  color: #605959;
  font-weight: 500;
  white-space: pre-line; }
  .fc-col-header-cell-cushion > a, .fc-list-day-cushion > a {
    color: #605959;
    font-weight: 600;
    letter-spacing: 1px; }
    .fc-col-header-cell-cushion > a:hover, .fc-list-day-cushion > a:hover {
      color: #605959 !important; }

.fc-list-day-cushion {
  background-color: #fff !important; }

.fc-daygrid-day:nth-child(even) {
  background: #fbf9ffcb !important; }

.fc-daygrid-day-number {
  color: #B4B4B4;
  width: 30px;
  height: 30px;
  text-align: center; }

.fc-scrollgrid-sync-inner:hover .fc-daygrid-day-number {
  background-color: #FAF7FF;
  color: #8C61CB !important;
  border-radius: 8px;
  padding: 5px 6px;
  font-weight: 600; }

.fc-toolbar-chunk {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.fc {
  direction: ltr;
  text-align: left; }

.fullcalendar-timeGrid-header-weekday {
  font-size: 14px;
  color: #605959;
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: 1px; }

.fc-day-today .fullcalendar-timeGrid-header-weekday {
  color: #8C61CB; }

.fc-day-today .fullcalendar-timeGrid-header-date {
  background: #8C61CB;
  color: #fff; }

.fullcalendar-timeGrid-header-date {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  border-radius: 8px;
  padding: 2px 4px;
  min-width: 26px; }

.fc table {
  border-collapse: collapse;
  border-spacing: 0; }

html .fc,
.fc table {
  font-size: 1em;
  font-family: "Inter",Helvetica; }

.fc td,
.fc th {
  padding: 0;
  vertical-align: top; }

.fc th {
  text-align: right;
  padding: 14px 8px 4px !important;
  color: #666666;
  vertical-align: top; }

.fc-list-day th {
  padding: 0px !important; }

/* Header
------------------------------------------------------------------------*/
.fc-header td {
  white-space: nowrap;
  padding: 15px 10px 0px; }

.fc-header-left {
  width: 25%;
  text-align: left; }

.fc-header-center {
  text-align: center; }

.fc-header-right {
  width: 25%;
  text-align: right; }

.fc-header-title h2 {
  margin-top: 0;
  white-space: nowrap;
  font-size: 32px;
  font-weight: 100;
  margin-bottom: 10px; }

.fc .fc-header-space {
  padding-left: 10px; }

.fc-header .fc-button {
  margin-bottom: 1em;
  vertical-align: top; }

/* buttons edges butting together */
.fc-header .fc-button {
  margin-right: -1px; }

.fc-header .fc-corner-right,
.fc-header .ui-corner-right {
  /* theme */
  margin-right: 0;
  /* back to normal */ }

/* button layering (for border precedence) */
.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
  z-index: 2; }

.fc-header .fc-state-down {
  z-index: 3; }

.fc-header .fc-state-active,
.fc-header .ui-state-active {
  z-index: 4; }

/* Content
------------------------------------------------------------------------*/
.fc-content {
  clear: both;
  zoom: 1;
  /* for IE7, gives accurate coordinates for [un]freezeContentHeight */ }

.fc-view {
  width: 100%;
  overflow: hidden; }

/* Cell Styles
------------------------------------------------------------------------*/
/* <th>, usually */
.fc-widget-content {
  /* <td>, usually */
  border: 1px solid #e5e5e5; }

.fc-widget-header {
  border-bottom: 1px solid #EEE; }

.fc-state-highlight {
  /* <td> today cell */
  /* TODO: add .fc-today to <th> */
  /* background: #fcf8e3; */ }

.fc-day-today > div > div .fc-daygrid-day-number {
  background-color: #8C61CB;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 4px 6px;
  text-align: center; }

.fc-daygrid-day-number {
  letter-spacing: 0px;
  margin: 4px 8px; }

.fc-state-highlight > div > div.fc-daygrid-day-number {
  background-color: #ff3b30;
  color: #FFFFFF;
  border-radius: 50%;
  margin: 4px; }

.fc-cell-overlay {
  /* semi-transparent rectangle while dragging */
  background: #bce8f1;
  opacity: .3;
  filter: alpha(opacity=30);
  /* for IE */ }

.fc-daygrid-event {
  border-radius: 6px;
  margin-top: 0px !important; }

.fc-daygrid-more-link {
  color: #8C61CB !important;
  letter-spacing: 0px;
  font-weight: 600; }
  .fc-daygrid-more-link:hover {
    color: #8C61CB !important; }

/* Buttons
------------------------------------------------------------------------*/
.fc-button-group {
  background-color: #eee;
  border-radius: 8px; }

.fc-button {
  font-family: 'Inter' !important;
  font-weight: 500 !important;
  background-color: #EEE !important;
  border: none !important;
  border-radius: 10px !important;
  position: relative;
  display: inline-block;
  padding: .5em 1.2em !important;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize !important;
  cursor: pointer;
  color: #666666 !important;
  letter-spacing: 0px !important;
  margin: 4px !important; }
  .fc-button:focus {
    box-shadow: none !important; }

.fc-button-active {
  transition: all .3s;
  background-color: #fff !important;
  color: #8C61CB !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11) !important; }

.fc-prev-button, .fc-next-button {
  margin: 0  !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0.4em 0.65em !important; }
  .fc-prev-button:focus, .fc-next-button:focus {
    border: none !important;
    box-shadow: none !important; }
  .fc-prev-button:hover > .fc-icon, .fc-next-button:hover > .fc-icon {
    transition: all .3s;
    color: #605959; }
  .fc-prev-button > .fc-icon, .fc-next-button > .fc-icon {
    transition: all .3s;
    color: #CBD0DF; }

.fc-today-button {
  margin-left: 18px !important;
  background-color: #FAF6FF !important;
  color: #8C61CB !important; }

@media screen and (max-width: 700px) {
  .fc .fc-toolbar {
    justify-content: center; }
  .fc-toolbar-chunk {
    margin-bottom: 8px !important; }
  .fc-today-button {
    display: none !important; } }

.fc-state-default {
  /* non-theme */
  border: 1px solid; }

.fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/*
	Our default prev/next buttons use HTML entities like &lsaquo; &rsaquo; &laquo; &raquo;
	and we'll try to make them look good cross-browser.
*/
.fc-text-arrow {
  margin: 0 .4em;
  font-size: 2em;
  line-height: 23px;
  vertical-align: baseline;
  /* for IE7 */ }

.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow {
  /* for &lsaquo; &rsaquo; */
  font-weight: bold; }

/* icon (for jquery ui) */
.fc-button .fc-icon-wrap {
  position: relative;
  float: left;
  top: 50%; }

.fc-button .ui-icon {
  position: relative;
  float: left;
  margin-top: -50%;
  *margin-top: 0;
  *top: -50%; }

.fc-state-default {
  border-color: #ff3b30;
  color: #ff3b30; }

.fc-button-month.fc-state-default, .fc-button-agendaWeek.fc-state-default, .fc-button-agendaDay.fc-state-default {
  min-width: 67px;
  text-align: center;
  transition: all 0.2s;
  -webkit-transition: all 0.2s; }

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #FFE3E3; }

/* Global Event Styles
------------------------------------------------------------------------*/
.fc-event-container > * {
  z-index: 8; }

.fc-event-container > .ui-draggable-dragging,
.fc-event-container > .ui-resizable-resizing {
  z-index: 9; }

.fc-event {
  border: 1px solid #FFF;
  /* default BORDER color */
  background-color: #FFF;
  /* default BACKGROUND color */
  color: #919191;
  /* default TEXT color */
  font-size: 12px;
  cursor: default; }

.fc-event.chill {
  background-color: #f3dcf8; }

.fc-event.info {
  background-color: #c6ebfe; }

.fc-event.important {
  background-color: #FFBEBE; }

.fc-event.success {
  background-color: #BEFFBF; }

.fc-event:hover {
  opacity: 0.7; }

a.fc-event {
  text-decoration: none; }

a.fc-event,
.fc-event-draggable {
  cursor: pointer; }

.fc-rtl .fc-event {
  text-align: right; }

.fc-event-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 15px; }

.fc-event-time,
.fc-event-title {
  padding: 0 1px; }

.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  /* hacky spaces (IE6/7) */
  font-size: 300%;
  /* */
  line-height: 50%;
  /* */ }

/* Horizontal Events
------------------------------------------------------------------------*/
.fc-event-hori {
  border-width: 1px 0;
  margin-bottom: 1px; }

.fc-ltr .fc-event-hori.fc-event-start,
.fc-rtl .fc-event-hori.fc-event-end {
  border-left-width: 1px;
  /*
border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
*/ }

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-right-width: 1px;
  /*
border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
*/ }

/* resizable */
.fc-event-hori .ui-resizable-e {
  top: 0           !important;
  /* importants override pre jquery ui 1.7 styles */
  right: -3px      !important;
  width: 7px       !important;
  height: 100%     !important;
  cursor: e-resize; }

.fc-event-hori .ui-resizable-w {
  top: 0           !important;
  left: -3px       !important;
  width: 7px       !important;
  height: 100%     !important;
  cursor: w-resize; }

.fc-event-hori .ui-resizable-handle {
  _padding-bottom: 14px;
  /* IE6 had 0 height */ }

/* Reusable Separate-border Table
------------------------------------------------------------*/
table.fc-border-separate {
  border-collapse: separate; }

.fc-border-separate th,
.fc-border-separate td {
  border-width: 1px 0 0 1px; }

.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
  border-right-width: 1px; }

.fc-border-separate .fc-week .fc-first {
  border-left: 0; }

.fc-border-separate .fc-week .fc-last {
  border-right: 0; }

.fc-border-separate tr.fc-last th {
  border-bottom-width: 1px;
  border-color: #cdcdcd;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px; }

.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-first th {
  border-top-width: 0; }

/* Month View, Basic Week View, Basic Day View
------------------------------------------------------------------------*/
.fc-grid th {
  text-align: center; }

.fc .fc-week-number {
  width: 22px;
  text-align: center; }

.fc .fc-week-number div {
  padding: 0 2px; }

.fc-grid .fc-day-number {
  float: right;
  padding: 0 2px; }

.fc-grid .fc-other-month .fc-day-number {
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
  /* opacity with small font can sometimes look too faded
	   might want to set the 'color' property instead
	   making day-numbers bold also fixes the problem */ }

.fc-grid .fc-day-content {
  clear: both;
  padding: 2px 2px 1px;
  /* distance between events and day edges */ }

/* event styles */
.fc-grid .fc-event-time {
  font-weight: bold; }

/* right-to-left */
.fc-rtl .fc-grid .fc-day-number {
  float: left; }

.fc-rtl .fc-grid .fc-event-time {
  float: right; }

/* Agenda Week View, Agenda Day View
------------------------------------------------------------------------*/
.fc-agenda table {
  border-collapse: separate; }

.fc-agenda-days th {
  text-align: center; }

.fc-agenda .fc-agenda-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: right;
  white-space: nowrap;
  font-weight: normal; }

.fc-agenda .fc-week-number {
  font-weight: bold; }

.fc-agenda .fc-day-content {
  padding: 2px 2px 1px; }

/* make axis border take precedence */
.fc-agenda-days .fc-agenda-axis {
  border-right-width: 1px; }

.fc-agenda-days .fc-col0 {
  border-left-width: 0; }

/* all-day area */
.fc-agenda-allday th {
  border-width: 0 1px; }

.fc-agenda-allday .fc-day-content {
  min-height: 34px;
  /* TODO: doesnt work well in quirksmode */
  _height: 34px; }

/* divider (between all-day and slots) */
.fc-agenda-divider-inner {
  height: 2px;
  overflow: hidden; }

.fc-widget-header .fc-agenda-divider-inner {
  background: #eee; }

/* slot rows */
.fc-agenda-slots th {
  border-width: 1px 1px 0; }

.fc-agenda-slots td {
  border-width: 1px 0 0;
  background: none; }

.fc-agenda-slots td div {
  height: 20px; }

.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
  border-top-width: 0; }

.fc-agenda-slots tr.fc-minor th.ui-widget-header {
  *border-top-style: solid;
  /* doesn't work with background in IE6/7 */ }

/* Vertical Events
------------------------------------------------------------------------*/
.fc-event-vert {
  border-width: 0 1px; }

.fc-event-vert.fc-event-start {
  border-top-width: 1px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.fc-event-vert.fc-event-end {
  border-bottom-width: 1px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }

.fc-event-vert .fc-event-time {
  white-space: nowrap;
  font-size: 10px; }

.fc-event-vert .fc-event-inner {
  position: relative;
  z-index: 2; }

.fc-event-vert .fc-event-bg {
  /* makes the event lighter w/ a semi-transparent overlay  */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .25;
  filter: alpha(opacity=25); }

.fc .ui-draggable-dragging .fc-event-bg,
.fc-select-helper .fc-event-bg {
  display: none\9;
  /* for IE6/7/8. nested opacity filters while dragging don't work */ }

/* resizable */
.fc-event-vert .ui-resizable-s {
  bottom: 0        !important;
  /* importants override pre jquery ui 1.7 styles */
  width: 100%      !important;
  height: 8px      !important;
  overflow: hidden !important;
  line-height: 8px !important;
  font-size: 11px  !important;
  font-family: monospace;
  text-align: center;
  cursor: s-resize; }

.fc-agenda .ui-resizable-resizing {
  /* TODO: better selector */
  _overflow: hidden; }

thead tr.fc-first {
  background-color: #f7f7f7; }

table.fc-header {
  background-color: #FFFFFF;
  border-radius: 6px 6px 0 0; }

.fc-week .fc-day > div .fc-day-number {
  font-size: 15px;
  margin: 2px;
  min-width: 19px;
  padding: 6px;
  text-align: center; }

.fc-sun, .fc-sat {
  color: #b8b8b8; }

.fc-week .fc-day:hover .fc-day-number {
  background-color: #FFE8EA;
  border-radius: 50%;
  color: #D34B57;
  transition: background-color 0.2s; }

.fc-week .fc-day.fc-state-highlight:hover .fc-day-number {
  background-color: #ff3b30; }

.fc-view-agendaDay thead tr.fc-first .fc-widget-header {
  text-align: right;
  padding-right: 10px; }

@charset "UTF-8";
.upload-student-tab-container {
  position: relative;
  letter-spacing: initial;
  --status-upload-bg: #ecfef4;
  --status-upload-icon: #34d399;
  --status-upload-text: #12644e;
  --status-error-bg: #fef2f2;
  --status-error-icon: #f87171;
  --status-error-text: #aa4240;
  --status-left-bg: #fffbec;
  --status-left-icon: #fbbf24;
  --status-left-text: #b6902e; }
  .upload-student-tab-container .ant-btn {
    font-size: 13px;
    border: 1px solid #00000014;
    box-shadow: none; }
    .upload-student-tab-container .ant-btn span {
      margin-left: unset; }
    .upload-student-tab-container .ant-btn[disabled] {
      background: #ebeaeb;
      color: #6f706ff2; }
  .upload-student-tab-container .upload-student-nav-btns {
    display: flex;
    grid-gap: 0.8em;
    gap: 0.8em; }
  .upload-student-tab-container .turncate {
    width: 16ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .upload-student-tab-container .upload-file-name {
    font-size: 10px;
    text-transform: lowercase;
    font-weight: 400;
    color: #7d7d7d; }
  .upload-student-tab-container .default-btn {
    --text-clr: #24292f;
    --icon-clr: #111;
    font-weight: 500;
    font-size: 12px;
    background-color: #f6f8fa; }
    .upload-student-tab-container .default-btn span {
      margin: 0; }
    .upload-student-tab-container .default-btn p {
      color: #111; }
    .upload-student-tab-container .default-btn svg {
      width: 14px !important;
      height: 14px !important;
      color: var(--icon-clr); }
    .upload-student-tab-container .default-btn:hover, .upload-student-tab-container .default-btn:focus {
      color: inherit;
      box-shadow: none; }
  .upload-student-tab-container .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    grid-gap: 1.2em;
    gap: 1.2em;
    margin-inline: auto;
    text-align: center;
    padding: 2em;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A2ABB3FF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s; }
    .upload-student-tab-container .upload-container:hover {
      transition: all 0.2s ease-in-out;
      background-color: #9096980d; }
    .upload-student-tab-container .upload-container h3 {
      font-size: 1.4em; }
    .upload-student-tab-container .upload-container .uploaded-text {
      color: #22222296; }
    .upload-student-tab-container .upload-container .upload-drag-info {
      display: flex;
      flex-direction: column;
      grid-gap: 0.5em;
      gap: 0.5em; }
    .upload-student-tab-container .upload-container > * + * {
      margin-top: 10px; }
    .upload-student-tab-container .upload-container .anticon {
      color: #3fa9ff;
      font-size: 48px; }
  .upload-student-tab-container .file-name-container {
    color: #111;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
    border: 1px solid #e8e7e8;
    border-radius: 10px;
    padding: 1em; }
    .upload-student-tab-container .file-name-container .ant-spin {
      margin-right: 10px; }
  .upload-student-tab-container .remove-file {
    color: #ef4444;
    letter-spacing: 0.82px;
    font-size: 12px;
    cursor: pointer; }
  .upload-student-tab-container .file-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em; }
  .upload-student-tab-container .file-name {
    font-weight: 500;
    font-size: 13px;
    width: 18ch;
    letter-spacing: 0.1em; }
  .upload-student-tab-container .select-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em; }
    .upload-student-tab-container .select-container .select-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 0.5em;
      gap: 0.5em; }
      .upload-student-tab-container .select-container .select-wrapper > div:nth-child(1) {
        letter-spacing: 1.2px;
        flex-basis: 27%;
        color: #6e7982; }
      .upload-student-tab-container .select-container .select-wrapper .ant-select {
        flex-grow: 1;
        height: 100%; }
  .upload-student-tab-container .ant-form-explain {
    margin-top: 3px;
    font-size: 12px;
    letter-spacing: 0.3px; }
  .upload-student-tab-container .upload-student-nav {
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1em;
    gap: 1em; }
    .upload-student-tab-container .upload-student-nav .nav-button-container {
      margin-left: auto;
      display: flex;
      grid-gap: 1em;
      gap: 1em; }
    .upload-student-tab-container .upload-student-nav .ant-icon {
      width: unset; }
  .upload-student-tab-container .placeholder-header {
    width: 100%;
    display: flex;
    grid-gap: 0.5em;
    gap: 0.5em;
    justify-content: space-between;
    letter-spacing: 1px;
    color: #191616;
    font-weight: 500;
    margin-bottom: 20px; }
    .upload-student-tab-container .placeholder-header .file-column {
      flex-basis: 27%; }
    .upload-student-tab-container .placeholder-header .select-column {
      flex-grow: 1; }
  .upload-student-tab-container .drop-overlay {
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    position: fixed !important;
    inset: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #000000d4;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12vw;
    color: #ffff; }
    .upload-student-tab-container .drop-overlay p {
      pointer-events: none; }
  .upload-student-tab-container .data-status-upload {
    color: #34a2d3;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-left: auto; }
  .upload-student-tab-container .data-status-uploading-container {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.8px;
    grid-gap: 0.8em;
    gap: 0.8em; }
  .upload-student-tab-container .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em; }
    .upload-student-tab-container .ant-btn svg {
      display: block; }
  .upload-student-tab-container .status {
    color: #111;
    display: flex;
    padding: 0.5em;
    padding-inline: 0.7em;
    border-radius: 50px;
    align-items: center;
    cursor: pointer;
    grid-gap: 0.6em;
    gap: 0.6em;
    border: 1px solid #00000014;
    text-transform: capitalize; }
    .upload-student-tab-container .status p {
      letter-spacing: 0.3px; }
    .upload-student-tab-container .status .icon {
      width: 8px;
      height: 8px;
      border-radius: 2px; }
    .upload-student-tab-container .status[data-type="upload"] .icon {
      background: var(--status-upload-icon); }
    .upload-student-tab-container .status[data-type="error"] .icon {
      background: var(--status-error-icon); }
    .upload-student-tab-container .status[data-type="left"] .icon {
      background: var(--status-left-icon); }
    .upload-student-tab-container .status span {
      font-weight: bold;
      font-size: 12px; }
  .upload-student-tab-container .total-data {
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-left: auto; }
    .upload-student-tab-container .total-data svg {
      stroke: #111; }
    .upload-student-tab-container .total-data p {
      font-weight: bold;
      font-size: 15px; }
  .upload-student-tab-container .upload-student-tab-container .ant-spin {
    width: unset; }
  .upload-student-tab-container [data-type="upload"] .icon {
    background: var(--status-upload-icon); }
  .upload-student-tab-container [data-type="error"] .icon {
    background: var(--status-error-icon); }
  .upload-student-tab-container [data-type="left"] .icon {
    background: var(--status-left-icon); }
  .upload-student-tab-container .student-data-status {
    display: flex;
    grid-gap: 0.5em;
    gap: 0.5em;
    align-items: center; }
    .upload-student-tab-container .student-data-status .icon {
      width: 10px;
      height: 10px;
      border-radius: 2px; }
    .upload-student-tab-container .student-data-status[data-type="upload"] p {
      color: var(--status-upload-text); }
    .upload-student-tab-container .student-data-status[data-type="upload"] .icon {
      background: var(--status-upload-icon); }
    .upload-student-tab-container .student-data-status[data-type="error"] p {
      color: var(--status-error-text); }
    .upload-student-tab-container .student-data-status[data-type="error"] .icon {
      background: var(--status-error-icon); }
    .upload-student-tab-container .student-data-status[data-type="left"] p {
      color: var(--status-left-text); }
    .upload-student-tab-container .student-data-status[data-type="left"] .icon {
      background: var(--status-left-icon); }
  .upload-student-tab-container .tab-active {
    border: none;
    background-color: #f0f1f7; }
  .upload-student-tab-container .student-data-error {
    color: #e84b29; }
  .upload-student-tab-container .editable-cell-value-wrap {
    border: 1px solid transparent;
    padding: 0.5em; }
  .upload-student-tab-container tbody > tr:hover > td {
    background: unset !important; }
  .upload-student-tab-container .status:nth-child(1) {
    margin-right: 16px; }
  .upload-student-tab-container .ant-header-title {
    padding-left: 7px;
    display: flex;
    align-items: center;
    grid-gap: 0.4em;
    gap: 0.4em; }
    .upload-student-tab-container .ant-header-title svg {
      display: block;
      cursor: pointer; }
      .upload-student-tab-container .ant-header-title svg.ant-popover-open {
        color: #1690ff;
        stroke: #1690ff;
        transition: 0.2s; }
  .upload-student-tab-container > * + * {
    margin-top: 1.6em; }
  .upload-student-tab-container p {
    margin: 0; }
  .upload-student-tab-container .ant-spin {
    width: 100%; }
  .upload-student-tab-container .error {
    color: red;
    font-size: 12px;
    letter-spacing: 1px; }
  .upload-student-tab-container .file-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    padding: 0.5em;
    display: flex;
    align-items: center;
    color: #6d747a;
    font-weight: 600;
    font-size: 15px;
    padding-inline: 16px;
    grid-gap: 0.5em;
    gap: 0.5em; }
  .upload-student-tab-container .file-info {
    font-size: 13px;
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    color: #3a3f43;
    font-weight: 500; }
  .upload-student-tab-container .ant-table-small {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-top: 1px solid transparent; }
  .upload-student-tab-container table .ant-table-thead > tr > th {
    font-weight: 600; }
  .upload-student-tab-container table .table-cell-input {
    resize: none;
    box-sizing: border-box;
    color: #111;
    outline: 1px solid transparent; }
    .upload-student-tab-container table .table-cell-input[data-type="lowercase"] {
      text-transform: lowercase; }
    .upload-student-tab-container table .table-cell-input[data-type="uppercase"] {
      text-transform: uppercase; }
    .upload-student-tab-container table .table-cell-input[data-type="capitalize"] {
      text-transform: capitalize; }
  .upload-student-tab-container table .has-success .table-cell-input {
    transition: none;
    border: 1px solid transparent;
    outline: 1px solid transparent; }
    .upload-student-tab-container table .has-success .table-cell-input:focus {
      box-shadow: none;
      outline: 0;
      border: 1px solid #3fa9ff; }
    .upload-student-tab-container table .has-success .table-cell-input[data-editing="false"]:hover {
      outline: 1px solid rgba(17, 17, 17, 0.088); }
    .upload-student-tab-container table .has-success .table-cell-input[disabled] {
      background: none; }
      .upload-student-tab-container table .has-success .table-cell-input[disabled]:hover {
        border: 1px solid transparent;
        outline: 1px solid transparent; }
      .upload-student-tab-container table .has-success .table-cell-input[disabled]:focus {
        box-shadow: none;
        outline: 0;
        outline: 1px solid transparent; }
  .upload-student-tab-container .ant-table-wrapper {
    letter-spacing: 0 !important; }

.ant-select-dropdown {
  letter-spacing: 0px; }

.ant-message {
  font-weight: 500;
  letter-spacing: initial; }
  .ant-message .ant-message-error {
    color: #b91d1d !important; }
  .ant-message .ant-message-success {
    color: #48825a !important; }

.upload-students-table-popover .ant-popover-inner {
  padding: 0;
  font-weight: 500;
  letter-spacing: 0;
  max-width: 50ch; }
  .upload-students-table-popover .ant-popover-inner .ant-popover-inner-content {
    padding: 12px; }
  .upload-students-table-popover .ant-popover-inner ul {
    display: flex;
    flex-direction: column;
    grid-gap: 0.4em;
    gap: 0.4em;
    list-style: none;
    font-size: 13px;
    padding: 0;
    margin: 0; }
  .upload-students-table-popover .ant-popover-inner li::before {
    content: "\2713";
    margin-right: 10px;
    color: green; }

.upload-student-modal .turncate {
  width: 23ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.upload-student-modal * {
  letter-spacing: 0px !important; }

.upload-student-modal .ant-modal-body {
  letter-spacing: 1px;
  padding-bottom: 15px;
  padding: 0;
  padding-bottom: 25px; }

.upload-student-modal .ant-modal-content {
  --modal-bg: #fafbfe;
  --border-clr: #eceef1;
  --export-clr: #616b7e;
  --select-clr: #515458;
  --padding: 1em;
  --export-bg: #fafbfd;
  --arrow-circle-icon-bg: #fff;
  --primary-btn-bg: #638ae9;
  border-radius: 8px;
  background-color: var(--modal-bg) !important;
  padding: 1.5em; }
  .upload-student-modal .ant-modal-content p {
    margin: 0; }
  .upload-student-modal .ant-modal-content .ant-btn-default {
    background: transparent;
    font-weight: 600; }
  .upload-student-modal .ant-modal-content .ant-btn:nth-child(1) {
    background-color: transparent; }
  .upload-student-modal .ant-modal-content .ant-btn-primary {
    border: none;
    font-weight: bold; }
  .upload-student-modal .ant-modal-content .ant-select-arrow {
    color: #545c69; }
  .upload-student-modal .ant-modal-content .ant-select-selection {
    position: relative;
    height: 100% !important;
    display: flex;
    align-items: center;
    border: 0 !important;
    border-top: 1px solid var(--border-clr) !important;
    border-left: 0px dotted var(--border-clr) !important;
    padding-left: var(--padding) !important;
    color: var(--select-clr) !important;
    font-weight: 600; }
    .upload-student-modal .ant-modal-content .ant-select-selection:focus {
      border: unset;
      box-shadow: unset; }
  .upload-student-modal .ant-modal-content .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px; }
  .upload-student-modal .ant-modal-content .header {
    color: #111;
    font-size: 16px;
    font-weight: bold; }
  .upload-student-modal .ant-modal-content .select-headers {
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .upload-student-modal .ant-modal-content .export-headers,
  .upload-student-modal .ant-modal-content .choose-headers {
    position: relative;
    border: 1px solid var(--border-clr);
    border-top: none; }
  .upload-student-modal .ant-modal-content .choose-headers {
    border-left: none; }
  .upload-student-modal .ant-modal-content .export-headers > div {
    padding-left: var(--padding);
    color: var(--export-clr);
    font-weight: 500;
    font-size: 14px;
    background-color: var(--export-bg);
    border-top: 1px solid var(--border-clr);
    display: flex;
    align-items: center; }
  .upload-student-modal .ant-modal-content .export-headers,
  .upload-student-modal .ant-modal-content .choose-headers {
    display: grid;
    grid-auto-rows: 42px; }
  .upload-student-modal .ant-modal-content .ant-modal-footer {
    border-top: none;
    padding: 0; }
  .upload-student-modal .ant-modal-content .error-text {
    color: #f87171;
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px; }

.ant-modal > * {
  letter-spacing: 0px !important; }

.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A2ABB3FF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
  transition: border-color 0.3s;
  text-align: center; }
  .upload-container:hover {
    transition: all 0.2s ease-in-out;
    background-color: #9096980d; }
  .upload-container.disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed; }

.lab-configure-title-container,
.lab-configure-overview-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.2em;
  justify-content: space-between; }

.upload-overview-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em; }

.lab-info {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  background-color: #fbf9fe;
  border: 2px solid #efe3e3;
  border-radius: 6px;
  padding: 5px 10px; }
  .lab-info.secondary {
    background: rgba(85, 85, 85, 0.03);
    border: 2px solid #ebebeb; }

.lab-info-item-label {
  font-size: 12px;
  display: flex;
  color: #4d4e52; }

.lab-info-item-value {
  color: #111;
  font-size: 14px;
  font-weight: 500; }

.upload-container {
  box-sizing: border-box;
  padding: 20px; }

.ant-modal-body {
  padding-top: 10px !important; }

.ant-table-body, .ant-table-fixed-right, table {
  border: 1px solid rgba(214, 206, 206, 0.36);
  padding: 10px;
  padding: 0px !important;
  border-radius: 6px; }
  .ant-table-body thead > tr > th, .ant-table-fixed-right thead > tr > th, table thead > tr > th {
    padding: 8px;
    font-size: 12px;
    background-color: transparent !important; }
  .ant-table-body .ant-table-tbody > tr > td, .ant-table-fixed-right .ant-table-tbody > tr > td, table .ant-table-tbody > tr > td {
    padding: 4px !important;
    border: 0px;
    font-size: 13px; }

.status-pill {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  font-weight: 400;
  border-radius: 9px;
  padding: 3px;
  font-size: 13px;
  padding-inline: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .status-pill .dot {
    width: 5px;
    border-radius: 50%;
    height: 5px;
    background-color: currentColor; }
  .status-pill.new {
    background: #f1f7fe;
    color: #355aa5; }
  .status-pill.success {
    color: #559e80;
    background: #ecfef2; }
  .status-pill.default {
    background-color: #F4F4F2;
    color: #969283; }
  .status-pill.tab {
    border-radius: 5px; }

.upload-status {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  color: #446FAE;
  font-weight: 500;
  font-size: 13px; }
  .upload-status.uploaded {
    color: #559E80; }
  .upload-status svg {
    color: currentColor;
    fill: currentColor; }

.handle-btns {
  margin-left: auto; }

.submit-footer {
  display: flex;
  align-items: center;
  grid-gap: 1em;
  gap: 1em; }

.dot {
  width: 5px;
  border-radius: 50%;
  height: 5px;
  margin-left: 4px;
  background-color: currentColor; }
  .dot.new {
    background-color: #3ABB40; }
  .dot.old {
    background-color: #6E82AC; }
  .dot.large {
    width: 7px;
    height: 7px; }
  .dot.align {
    position: relative;
    left: -4px; }

.upload-overview-table {
  max-height: 200px;
  overflow: auto; }

.add-lab-text {
  font-size: 12px;
  margin-left: 4px; }
  .add-lab-text span {
    cursor: pointer;
    color: #00ADE6;
    text-decoration: underline; }

.ant-modal {
  min-width: 600px !important; }

.error {
  color: #ba4343;
  font-size: 13px; }

.ant-modal-header {
  padding: 12px !important; }

.ant-modal-title {
  font-size: 15px !important; }

.ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px; }

.labconfig-table table > thead > tr {
  line-height: 0; }

.labconfig-table table {
  border-bottom: 0px;
  background: #fff; }

.component-tab-dlt-btn {
  color: red;
  width: 13px;
  position: absolute;
  right: 5px; }

.component-dropdown {
  position: absolute;
  bottom: -33px;
  right: 0;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  color: #F65151;
  background-color: #fff;
  box-shadow: 0px 0px 1px #D0D2D6;
  padding: 7px; }

.component-dropdown-btn {
  border: 0.9px solid #E2E7F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg); }
  .component-dropdown-btn .component-dropdown {
    opacity: 0; }
  .component-dropdown-btn:hover .component-dropdown {
    opacity: 1; }

.component-menu-item {
  display: flex;
  color: #F65151; }
  .component-menu-item .ant-icon-delete {
    margin-left: 0px !important; }

.iframe-preview-modal {
  letter-spacing: 0; }
  .iframe-preview-modal .ant-modal-content, .iframe-preview-modal .ant-modal-body {
    height: 100%;
    overflow: auto; }

.component-dropdown {
  letter-spacing: 0; }

.add-session-modal {
  overflow: hidden; }
  .add-session-modal .cs-session-container {
    display: grid;
    grid-template-columns: 0.6fr 200px 130px 1fr 1fr;
    grid-gap: 10px;
    gap: 10px; }
  .add-session-modal .cs-components-draggable {
    padding-bottom: 10px; }
    .add-session-modal .cs-components-draggable::-webkit-scrollbar {
      width: 0px;
      background: transparent; }
  .add-session-modal > * {
    letter-spacing: normal; }
  .add-session-modal .cs-flow {
    --space: 0.8rem; }
    .add-session-modal .cs-flow > * + * {
      margin-bottom: var(--space); }
  .add-session-modal .cs-component-pools {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    .add-session-modal .cs-component-pools .componentTags {
      cursor: pointer;
      display: flex;
      justify-content: center;
      grid-gap: 12px;
      gap: 12px;
      align-items: center;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 30px;
      background-color: #F2F4F8;
      color: #44484E;
      font-weight: 600;
      font-size: 11px; }
      .add-session-modal .cs-component-pools .componentTags p span {
        color: #111;
        font-weight: bold;
        margin-left: 4px; }
      .add-session-modal .cs-component-pools .componentTags svg {
        color: #5C55E7; }
    .add-session-modal .cs-component-pools .cs-component-upload-file {
      display: flex;
      grid-gap: 20px;
      gap: 20px; }
  .add-session-modal .cs-input-container {
    display: flex;
    flex-direction: column;
    letter-spacing: 0; }
    .add-session-modal .cs-input-container .ant-select {
      width: 100%;
      min-width: unset; }
  .add-session-modal .cs-label {
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 500; }
  .add-session-modal .cs-ref-content-container {
    margin-top: 20px; }
    .add-session-modal .cs-ref-content-container input[type="text"] {
      max-width: 400px; }
  .add-session-modal .cs-ref-content {
    display: flex;
    flex-direction: column;
    grid-gap: 6px;
    gap: 6px; }
  .add-session-modal .cs-preview-doc-btn {
    font-size: 13px;
    height: 31px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    border-color: #5C55E7;
    color: #5C55E7; }
  .add-session-modal .cs-enable-doc-check {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: #536471; }
    .add-session-modal .cs-enable-doc-check .ant-checkbox .ant-checkbox-inner {
      border: 1px solid #3C4044; }
    .add-session-modal .cs-enable-doc-check .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #5C55E7;
      border-color: #5C55E7; }
      .add-session-modal .cs-enable-doc-check .ant-checkbox-checked .ant-checkbox-inner:hover {
        border: none; }

.practice-modal-label {
  font-size: 14px;
  color: #374151; }

/** Antd Custom Select Styles */
.custom-selectOption:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: #FAF7FF !important; }

.custom-selectOption.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #FAF7FF !important; }

/** FullCalendar Custom Button Style */
.fc-sessionTypeFilter-button, .fc-datePicker-button {
  background: transparent !important; }

.fc-verticalFilter-button, .fc-datePicker-button {
  background: transparent !important; }

.fc-paySlab-button, .fc-datePicker-button {
  background: transparent !important; }

.fc-stages-button, .fc-datePicker-button {
  background: transparent !important; }

.fc-sideView-button, .fc-datePicker-button {
  background: transparent !important;
  cursor: auto !important;
  right: 3%;
  position: absolute;
  top: 9.7%;
  z-index: 100;
  width: 45%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  border-radius: 0 !important;
  cursor: auto;
  margin: 0; }

.fc-empty-button, .fc-datePicker-button {
  display: none !important; }

.styledTooltip-demandEvent {
  z-index: 9999; }
  .styledTooltip-demandEvent .ant-tooltip-inner {
    font-family: 'Inter';
    font-weight: 500;
    letter-spacing: 0px;
    background: white;
    color: black;
    border-radius: 8px; }
  .styledTooltip-demandEvent .ant-tooltip-arrow::before {
    bottom: 6px;
    width: 15px;
    height: 15px;
    background-color: white; }

.custom-ant-tooltip-inner {
  z-index: 9999; }
  .custom-ant-tooltip-inner .ant-tooltip-inner {
    font-family: 'Inter';
    font-weight: 500;
    letter-spacing: 0px;
    background: #FAF7FF;
    color: #8C61CB; }
  .custom-ant-tooltip-inner .ant-tooltip-arrow {
    opacity: 0; }

.studentTabTable tr td:nth-child(1) {
  font-size: 1.04167vw;
  font-weight: bold;
  width: 30%; }

.studentTabTable tr td:nth-child(2) {
  font-size: 1.04167vw;
  font-weight: bold; }

.studentTabTable tr td:nth-child(3) {
  font-size: 0.9375vw;
  font-weight: 400 !important; }

.studentTabTable tr td:nth-child(4) {
  font-size: 0.9375vw;
  font-weight: 400 !important; }

.studentTabTable tr td:nth-child(5) {
  font-size: 0.9375vw;
  font-weight: 400 !important; }

.studentTabTable tr td:nth-child(6) {
  font-size: 0.9375vw;
  font-weight: 400 !important; }

.studentTabTable th {
  color: #212121 !important;
  font-weight: bold !important;
  background-color: #F5F5F5 !important;
  font-size: 0.9375vw; }

.studentTabTable {
  padding-left: 20px;
  padding-right: 20px; }

.demand-supply-className {
  color: white;
  background-color: #8C61CB;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: white; }
  .demand-supply-className .ant-notification-notice-icon {
    margin: 0;
    margin-right: 4px;
    padding: 10px;
    display: flex;
    background-color: white;
    border-radius: 10px; }
  .demand-supply-className .ant-notification-notice-message {
    color: white; }
  .demand-supply-className .ant-notification-notice-close {
    color: white; }
    .demand-supply-className .ant-notification-notice-close:hover {
      color: white; }

.demand-supply-withIcon-className {
  color: white;
  background-color: #8C61CB;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: white; }
  .demand-supply-withIcon-className .ant-notification-notice-icon {
    margin: 0;
    margin-right: 4px;
    padding: 0;
    display: flex;
    background-color: white;
    border-radius: 30px; }
  .demand-supply-withIcon-className .ant-notification-notice-message {
    color: white; }
  .demand-supply-withIcon-className .ant-notification-notice-close {
    color: white; }
    .demand-supply-withIcon-className .ant-notification-notice-close:hover {
      color: white; }

h3, p, h5 {
  margin: 0%; }

.userImage {
  object-fit: cover;
  width: 32px;
  height: 32px;
  border-radius: 5px; }

.RatingWrapper {
  display: flex;
  align-items: center; }

.leftbarWrapper {
  height: 50%;
  width: 100%;
  background-color: white;
  padding: 1%;
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.leftbarTopContainer {
  display: flex;
  align-items: center;
  width: calc(100% - 2%);
  justify-content: space-between; }

.leftbarLogoImage {
  cursor: pointer; }

.logoContainer {
  background-color: #f5f5f5;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 8px;
  position: relative;
  cursor: pointer; }

.leftbarContentContainer {
  width: calc(100% - 2%);
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 8px; }

.DetailsContent {
  display: flex; }

.currentUserName {
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  width: 100%; }

.nameAnRatingWrapper {
  margin-left: 6px;
  width: 100%; }

.profileChangerBtn {
  position: relative;
  margin-right: 5%;
  width: 2px; }

.leftbarlistItem {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 3%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 30%;
  padding-top: 7%;
  padding-bottom: 7%; }

.leftbarlistItem:hover {
  background-color: #E6F7F5; }

.leftbarlistItem:hover h5 {
  color: #01AA93; }

.leftbarOptions {
  list-style-type: none;
  color: #212121;
  width: 100%;
  padding-left: 0%;
  font-size: 18px; }

.optionName {
  margin-left: 5%;
  transition: all 0.3s ease-in-out; }

.profileChangerBtn {
  position: relative;
  height: 100%; }

.previousButton {
  font-size: 10px;
  position: absolute;
  bottom: 50%;
  right: 5%;
  cursor: pointer;
  color: #aaa9a9; }

.nextButton {
  font-size: 10px;
  position: absolute;
  bottom: 20%;
  right: 5%;
  cursor: pointer;
  color: #aaa9a9;
  transform: rotateX(180deg); }

.rating {
  margin-left: 4px;
  font-size: 10px; }

.notificationBadge {
  position: absolute;
  height: 13px;
  width: 13px;
  top: 0%;
  transform: translateY(-50%);
  right: 0;
  transform: translateX(-10%);
  border-radius: 50%;
  background-color: #D34B57;
  color: white; }

.numberOfNotificationText {
  font-Size: 8px;
  letter-spacing: -0.2px;
  text-align: center;
  transform: translateX(-7%); }

.mailLogo {
  min-width: 15px;
  min-height: 15px; }

.leftbarContainer {
  height: 100vh;
  width: 18vw;
  padding: 1%; }

body {
  overflow-x: hidden; }

.studentProfileContainer {
  display: flex;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  max-width: 100vw;
  overflow: hidden; }

::-webkit-scrollbar {
  width: 6px; }

::-webkit-scrollbar-thumb {
  background: #CCCCCC;
  border-radius: 25px; }

.ProfileContainer {
  padding: 2%;
  width: calc(100vw - 18vw);
  background-color: #fcfafaec;
  overflow-y: auto;
  overflow-x: hidden; }

.profileTopSection {
  background-color: white;
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 196.75px; }

.profileTopSectionWrapper {
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between; }

.studentName {
  color: black;
  font-weight: bold;
  font-size: 20px; }

.statusText {
  text-transform: uppercase;
  color: #01AA93;
  background-color: #E6F7F5;
  padding: 2px;
  font-size: 12px; }

.statusWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
  width: 100%; }

.parentNameDetails, .parentEmailDetails, .parentPhoneDetails {
  display: flex; }

.parentNameDetails h4, .parentEmailDetails h4, .parentPhoneDetails h4 {
  font-size: 12px;
  margin-top: 2px;
  color: #424242; }

.parentPhoneDetails {
  width: 120%; }

.parentNameDetails p, .parentEmailDetails p, .parentPhoneDetails p {
  font-size: 10px;
  letter-spacing: -0.1px;
  color: #757575; }

.parentNameContainer, .parentEmailContainer, .parentPhoneContainer {
  margin-left: 5%; }

.parentPhoneContainer h4 {
  letter-spacing: -0.005px; }

.parentEmailDetails {
  width: -webkit-max-content;
  width: max-content; }

.userParentDetailsContainer {
  display: flex;
  width: 45%; }

.userAccountDetailsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 5%; }

.userImageAndDetailsContainer {
  display: flex;
  height: 100%;
  width: 50%; }

.userParentDetailsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.accountCreationDataText {
  font-size: 12px;
  color: #757575;
  word-spacing: -2px; }

.parentNameDetails {
  width: 40%; }

.parentPhoneDetails {
  width: 40%; }

.userParentDetailsflexWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between; }

.profileTabListContainer {
  display: flex;
  list-style-type: none;
  color: #A8A7A7;
  width: 30%;
  justify-content: space-between;
  margin: 0%;
  margin-top: 25px;
  height: 4.5vh;
  padding-left: 0%; }

.profileTabListContainer li {
  cursor: pointer; }

.active {
  color: #01AA93;
  position: relative; }

.active::after {
  content: '';
  position: absolute;
  bottom: 0%;
  background-color: #01AA93;
  left: 0%;
  width: 120%;
  height: 0px;
  transform: translateX(-10%);
  border-top: 4px solid #01AA93;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.seperator {
  margin: 0%;
  border: 1px solid #eeeeee; }

.borderBottom {
  position: absolute; }

.coursesCardContainer {
  margin-top: 15px; }

.coursesCardContainer > h3 {
  font-weight: bold;
  letter-spacing: -0.05px;
  color: #212121;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 2%; }

.cardsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.preloaderPostSales {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@media screen and (max-width: 987px) {
  .profileTabListContainer {
    width: 60%;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (max-width: 885px) {
  .userImageAndDetailsContainer {
    flex-direction: column;
    display: flex;
    align-items: center; }
  .profileTopSection {
    height: auto;
    max-height: 100%;
    padding-top: 25px;
    padding-bottom: 25px; }
  .userParentDetailsflexWrapper {
    display: flex;
    flex-direction: column; }
  .parentNameDetails {
    width: 100%; }
  .parentPhoneDetails {
    width: 100%; }
  .parentEmailDetails {
    width: 100%; }
  .studentName {
    margin-top: 1vh; }
  .profileTabListContainer {
    width: 80%;
    margin-left: auto;
    margin-right: auto; }
  .cardsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    position: relative; }
  .coursesCardContainer > h3 {
    text-align: center; }
  .coursesCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; } }

@media screen and (max-width: 585px) {
  .studentName {
    margin-top: 1vh;
    font-size: 18px; }
  .accountCreationDataText {
    word-spacing: -2px;
    font-size: 10px; }
  .statusWrapper {
    display: flex;
    align-items: center; }
  .statusWrapper {
    font-size: 11px;
    width: 90%; }
  .statusText {
    font-size: 12px; }
  .profileTopSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .parentsDetailsText {
    text-align: center; }
  .statusWrapper {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 100%; }
  .userImageAndDetailsContainer {
    width: 60%; }
  .studentName {
    font-size: 16px; } }

@media screen and (max-width: 450px) {
  .userParentDetailsContainer {
    width: auto; }
  .profileTopSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center; } }

@media screen and (max-width: 500px) {
  .profileTabListContainer li {
    font-size: 12px; } }

.courseDate {
  color: black;
  font-weight: 600;
  word-spacing: -2px; }

.singleCourseCardComponent h3 {
  color: black; }

.singleCourseCardComponent {
  color: #757575;
  width: 49%;
  font-family: 'Inter', sans-serif;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  padding-bottom: 40px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px; }

.expandedSingleCourseCardComponent {
  color: #757575;
  width: 100%;
  font-family: 'Inter', sans-serif;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  position: static;
  transform: translate(0, 0);
  padding-bottom: 30px;
  transition: all 0.3s ease-in-out; }

.sessionsDetails p {
  font-size: 10px; }

.sessionsDetails p:last-child {
  color: black;
  font-weight: bold; }

.sessionsDetails {
  display: flex;
  align-items: center;
  width: 40%;
  max-width: 130px;
  justify-content: space-between; }

.courseProgressDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.courseProgressDetails p {
  font-size: 10px; }

.courseProgressDetails p:last-child {
  color: black;
  font-weight: bold; }

.singleCourseCardWrapper {
  display: flex;
  width: 100%; }

.courseProgressBarTrack {
  position: relative;
  background-color: #EEEEEE;
  height: 5px;
  width: 70%; }

.progress {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: #01AA93;
  border-radius: 20px; }

.courseDetailsAndStatusContainer {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  width: 80%; }

.courseDetailsAndStatusContainer > p {
  font-size: 12px; }

.courseDetailsAndStatusContainer p {
  word-spacing: -1.5px;
  margin-top: 1.2%;
  margin-bottom: 1.2%; }

.courseDetailsAndStatusContainer h3 {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Inter', sans-serif; }

.imageContainer {
  padding: 3px;
  border-radius: 16px; }

.batchName {
  text-transform: uppercase; }

.topicThumbnailImage {
  width: 22px;
  height: 26px;
  margin-right: 10px; }

.classStatus {
  background-color: #E7FFFC;
  color: #01AA93;
  font-size: 10px;
  display: inline-block;
  margin-bottom: 2%;
  word-spacing: -2px; }

.singleClassCardWrapper {
  position: relative; }

.expandClassesBtn {
  border: none;
  outline: none;
  color: #01AA93;
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;
  word-spacing: -1.5px; }

.classAndCourseSeperator {
  width: 90%;
  margin: auto;
  margin-top: 2vh;
  margin-bottom: 3vh;
  border: 1px solid #EEEEEE; }

.singleClassCardWrapper {
  display: flex; }

.classImageContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.classDetailsAndStatusContainer {
  margin-left: 5%; }

.classImg {
  border-radius: 8px; }

.batchDetails {
  display: flex;
  align-items: center; }

.batchDetails p {
  font-size: 10px;
  margin-left: 10px; }

.batchName {
  color: black;
  font-size: 12px; }

.expandedSingleCourseCardSessionProgressBarOuter {
  width: 100%;
  height: 8px;
  margin-top: 10px;
  background-color: #EEEEEE;
  border-radius: 10px; }

.expandedSingleCourseCardSessionDetails {
  margin-top: 12px; }

.expandedSingleCourseCardSessionDetailsText {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.expandedSingleCourseCardSessionProgressBarInner {
  width: 22%;
  height: 6px;
  background-color: #01AA93;
  border-radius: 10px; }

.sessionsCompleteNumbers {
  display: flex;
  align-items: center; }

.sessionsCompleteNumberDetails {
  color: #333333;
  margin-left: 5px; }

.expandedComponentSessionDetailsTableContainer {
  width: 100%;
  margin-top: 20px;
  opacity: 1;
  margin-bottom: 20px; }

.expandedComponentSessionDetailsTableContainer th {
  width: 20%;
  text-align: center;
  background-color: #F5F5F5;
  font-weight: 500; }

.expandedComponentSessionDetailsTableContainer td {
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
  text-align: center; }

.expandedComponentSessionDetailsTableContainer td h4 {
  min-width: 70%; }

.collapsedSingleCourseCardComponent {
  width: 100%;
  opacity: 0;
  z-index: -5;
  position: absolute;
  height: 0px;
  transform: translateY(-100%);
  transition: all 0.3s linear; }

.expandedSingleCourseCardTableComponent {
  width: 100%;
  transform: translateY(0) scaleY(1);
  transition: all 0.3s linear; }

.hiddenExpandedComponentSessionDetailsTableContainer {
  height: 0px;
  width: 0%;
  position: absolute;
  z-index: -5;
  background-color: red; }

.sessionsCompleteNumbers p:first-child {
  color: #808080;
  font-size: 14px; }

.sessionsCompleteNumberDetails {
  color: #212121;
  font-size: 14px; }

.expandedCardSessionProgressPercentageText {
  color: #333333;
  font-size: 14px; }

.viewReportDetailsAndExpandBtnContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 1%;
  bottom: -50%; }

.viewReportDetailsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  outline: none;
  background-color: #01AA93;
  padding-block: 5px;
  padding-inline: 7px;
  font-size: 12px;
  border-radius: 8px;
  margin-left: auto;
  cursor: pointer;
  margin-left: 5px; }

@media screen and (max-width: 885px) {
  .singleCourseCardComponent {
    width: 80%;
    margin-top: 15px; }
  .sessionsDetails {
    width: 50%; }
  .batchDetails {
    width: 45%; }
  .weeklyDate {
    font-size: 11px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%; }
  .sessionsDetails {
    width: 90%; }
  .courseProgressBarTrack {
    width: 40%; } }

.courseThumbnailImage {
  height: 83px;
  width: 87px; }

@media screen and (max-width: 585px) {
  .singleCourseCardComponent {
    width: 95%; }
  .batchDetails {
    width: 50%; } }

@media screen and (max-width: 450px) {
  .batchDetails {
    width: 60%; } }

.databaseIcon {
  margin-right: 5px; }

.downloadReportBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  outline: none;
  background-color: #01AA93;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 8px;
  margin-left: auto;
  cursor: pointer; }

.paymentsTopSectionWrapper {
  display: flex;
  align-items: center; }

.paymentsTopSectionImgContainer {
  background-color: #eeeeee;
  padding: 4px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 5px; }

.paymentsSectionSeperator {
  border: 2px solid #eeeeee;
  margin-top: 2vh;
  margin-bottom: 2vh; }

.paymentActionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.paymentsSectionTable td {
  color: #212121;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding-block: 15px; }

.paymentsSectionTable th {
  color: #212121;
  text-align: center; }

.paymentStatusContainer {
  display: flex;
  align-items: center;
  justify-content: center; }

.paymentStatusContainer p {
  color: #7D3F3A;
  font-size: 10px;
  text-transform: capitalize;
  margin-left: 4px; }

.paymentsSectionContainer {
  width: 100%;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  margin-top: 10px; }

.paymentsTopSectionWrapper {
  display: flex;
  width: 100%;
  align-items: center; }

.paymentsSectionTable {
  width: 100%; }

.installmentProgressBar {
  height: 8px;
  background-color: #eeeeee;
  border-radius: 25px;
  width: 100%;
  position: relative;
  margin-top: 2vh;
  margin-bottom: 3vh; }

.installmentProgressBarInner {
  position: absolute;
  height: 100%;
  background-color: #01AA93;
  border-radius: 25px; }

.paymentsTopSectionTextContainer {
  margin-left: 15px; }

.installmentCompleteText {
  color: #333333; }

@media screen and (max-width: 961px) {
  .paymentsSectionTable td, .paymentsSectionTable th {
    padding: 8px; }
  .paymentsSectionTable td {
    padding: 8px;
    font-size: 12px; }
  .downloadReportBtn {
    font-size: 12px; } }

@media screen and (max-width: 873px) {
  .paymentsSectionTable td {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 10px; } }

@media screen and (max-width: 738px) {
  .paymentsTopSectionTextContainer {
    width: 70%; }
  .paymentsTopSectionWrapper {
    display: flex;
    flex-wrap: wrap; } }

.reportProgressBarDetailsOuter {
  width: 100%;
  height: 8px;
  border-radius: 25px;
  background-color: #EEEEEE;
  margin-top: 15px;
  position: relative; }

.reportProgressBarDetailsInner {
  position: absolute;
  background-color: #01AA93;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 25px; }

.courseReportCardImage {
  width: 82px;
  height: 80px;
  border-radius: 8px;
  background-color: #EEEEEE;
  padding: 8px; }

.reportCardContainer {
  background-color: white;
  padding-block: 30px;
  padding-inline: 25px; }

.reportProgressDetailsTextContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px; }

.reportProgressPercentageText {
  display: inline-block;
  margin-left: auto;
  color: black;
  font-weight: 600; }

.reportProgressDetailsTextContainer p:first-child {
  color: #808080; }

.reportCardTopSectionContainerLeftContainer {
  display: flex;
  align-items: center; }

.reportCardTopSectionContainer {
  display: flex;
  justify-content: space-between; }

.reportProgressDetailsTextContainer > p:nth-child(2) {
  color: red;
  color: black;
  font-weight: 500;
  margin-left: 8px;
  letter-spacing: -0.1px; }

.reportCardSessionViewReportBtn p {
  margin-left: 5px;
  margin: 0%; }

.reportCardSessionViewReportBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #01AA93;
  color: white;
  padding-inline: 10px;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  height: 36px;
  outline: none; }

.reportNameAndDurationText {
  margin-left: 10px; }

.reportNameAndDurationText h2 {
  margin-bottom: 0;
  font-size: 18px;
  color: black;
  margin-bottom: 5%;
  font-weight: bold; }

.reportNameAndDurationText p {
  font-size: 12px;
  color: #808080; }

.skeletonLoaderComponent {
  background: linear-gradient(0.25turn, transparent, #fff, transparent), linear-gradient(#eee, #eee), radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-position: -1500px 0, 0 0, 0px 190px, 50px 195px;
  background-size: 1500px 250px, 100% 180px, 100px 100px, 225px 30px;
  padding-block: 30px;
  padding-inline: 25px;
  height: 200px;
  border-radius: 8px;
  animation: loading 2s infinite;
  margin-block: 10px; }

@keyframes loading {
  to {
    background-position: 1500px 0, 0 0, 0 190px, 50px 195px; } }

.reportDate {
  color: #9E9E9E;
  font-size: 10px;
  margin-bottom: 15px; }

.paymentStatus {
  background-color: #01AA93;
  color: white;
  font-size: 8px;
  display: inline-block;
  padding: 2px 4px;
  border-radius: 5px; }

.batchNameText {
  color: #757575;
  font-size: 10px; }

.DescriptionTitle {
  color: #757575;
  font-size: 10px; }

.batchDetailsWrapper, .studentDetailsWrapper {
  display: flex;
  align-self: center; }

.noAttemptsDetailDesc > p {
  color: #757575;
  font-size: 12px; }

.batchAndStudentDetailsDesc {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: space-between; }

.programmingBasisDesc {
  font-size: 12px;
  margin-bottom: 12px; }

.studentMethod {
  display: flex;
  align-items: center;
  width: 8%;
  justify-content: space-between;
  margin-bottom: 20px;
  min-width: 110px; }

.reportsContainer {
  width: 100%;
  padding: 15px; }

.courseProgressBarInReportContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }

.courseProgressBar {
  position: relative;
  height: 6px;
  width: 77%; }

.courseProgressBarInner {
  position: absolute;
  left: 0;
  top: 0;
  width: 80%;
  background-color: #01AA93;
  height: 100%;
  border-radius: 20px; }

.reportsWrapper {
  display: flex;
  width: 100%;
  align-items: center; }

.reportDetailsDesc {
  margin-left: 1.5%;
  width: 100%; }

.methodDenotion {
  background-color: #FAAD14;
  border-radius: 5px;
  width: 18px;
  height: 18px; }

.reportCourseTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px; }

.batchDescriptionText {
  margin-left: 12px; }

.batchAndStudentDetailsDesc {
  margin-top: 15px;
  margin-bottom: 12px; }

.batchNameText {
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  font-size: 12px; }

.DescriptionTitle {
  color: #757575;
  font-size: 12px; }

.printReportBtn {
  width: 100%;
  background-color: #01AA93;
  border: none;
  outline: none;
  color: white;
  border-radius: 8px;
  padding: 6px 0px 6px 0px;
  margin-top: 30px;
  font-size: 12px; }

.studentPresentStatus, .studentAbsentStatus, .studentNotApplicableStatus {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px; }

.classesCalendarContainer {
  width: 100%; }

.classStudentStatus {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 36%;
  justify-content: space-around;
  margin-bottom: 3vh; }

.classStudentStatus p {
  font-size: 10px;
  margin-left: 5px; }

.classStatusIndicatorContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%; }

.classStatusIndicator {
  width: 27%;
  cursor: pointer;
  max-width: 30px;
  height: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center; }

.activeSession {
  width: 27%;
  cursor: pointer;
  max-width: 30px;
  height: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #01AA93; }

.classStatusIndicator p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #01AA93;
  font-size: 12px; }

.classesCalendarContainer th {
  width: 17%;
  background-color: white;
  color: #333333;
  font-size: 12px;
  text-align: center; }

.mentorProfilePic {
  width: 22px;
  height: 22px;
  border-radius: 50%; }

.classesCalendarContainer td {
  background-color: white; }

.weeksContainer {
  color: #333333;
  font-size: 12px;
  font-weight: bold; }

.nextYearText {
  margin-block: auto;
  background-color: white; }

.nextYearTableContainer th {
  background-color: white; }

.classCalendarReportWrapper {
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  height: 280px; }

.weeksContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 8%;
  height: 80%;
  margin-top: auto;
  min-width: 100px; }

.classCalendarReportWrapper table {
  width: 90%; }

.classesCalendarContainer {
  margin-top: 4vh; }

.assignedMentorRatingText {
  color: #FAAD14; }

.AssignedMentorRating {
  display: flex;
  align-items: center; }

.mentorDetails {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between; }

.classDetailsInReportTopSection {
  display: flex; }

.classDetailsInReportContainer {
  width: 39%;
  padding: 8px;
  padding-left: 20px;
  border-radius: 8px;
  margin-top: 4vh; }

.classDetailsInReportTimeDesc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #757575;
  font-size: 10px;
  max-width: 75%; }

.classDetailsInReportTopSectionDesc {
  width: 100%; }

.classDetailsText {
  color: #333333;
  margin-bottom: 2.5vh;
  font-size: 12px;
  font-weight: bold; }

.classDetailsInReportTopSection {
  width: 100%; }

.classDetailsInReportTopSectionDesc {
  margin-left: 5%; }

.classDetailsInReportProgressDetails {
  display: flex;
  width: 95%;
  justify-content: space-between; }

.classDetailsInReportProgressBarOuter {
  width: 95%;
  background-color: #eeeeee;
  height: 5px;
  position: relative;
  border-radius: 20px;
  margin-bottom: 3vh;
  margin-top: 1vh; }

.classDetailsInReportProgressBarInner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #01AA93;
  border-radius: 20px; }

.classDetailsInReportProgressDetails {
  margin-top: 15px; }

.classDetailsInReportProgressDetails p:first-child {
  font-size: 12px;
  color: #757575; }

.mentorAssignedText {
  font-size: 12px;
  color: #757575;
  margin-bottom: 2vh; }

.mentorAssignedName {
  transform: translateY(2px);
  margin-right: 15px;
  margin-left: 10px; }

.AssignedMentorRating {
  background-color: #FEF6E6;
  padding: 2px; }

.assignedMentorRatingText {
  font-size: 12px; }

.classDetailsInReportContainer {
  background-color: white;
  border-radius: 8px;
  padding-bottom: 15px; }

.classDetailsInReportFlexContainer {
  width: 100%; }

.attendanceCardText {
  font-size: 12px;
  color: #333333;
  font-weight: bold; }

.attendanceDetailsInReportTimeDesc {
  display: flex;
  align-items: center; }

.attendanceDetailsInReportTimeDesc > p {
  font-size: 12px;
  color: #757575;
  margin-left: 6px; }

.attendanceDetailsJoinDesc, .attendanceDetailsLeaveDesc {
  display: flex;
  align-items: center;
  max-width: 100%; }

.attendanceDetailsJoinDesc p:first-child, .attendanceDetailsLeaveDesc p:first-child {
  color: #757575;
  font-size: 12px; }

.classDetailsInReportFlexContainer {
  display: flex; }

.attendanceDetailsJoinDesc p:last-child, .attendanceDetailsLeaveDesc p:last-child {
  color: #333333;
  font-size: 14px;
  margin-left: 5px; }

.attendanceAndHomworkCardContainer {
  margin-top: 4vh; }

.attendanceDetailsInReportTimeDesc {
  font-size: 12px; }

.pieChart {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(white 0deg 45deg, #01AA93 45deg 360deg); }

.topicThumbnailImage {
  width: 52px;
  height: 62px; }

.pieChart h3 {
  color: #333333; }

.attendanceCardDescAndPieChartContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }

.pieChartContainer {
  background-color: white; }

.attendanceAndHomworkCardContainer {
  width: 61%; }

.attendanceCardContainer {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  margin-left: 5%;
  width: 52%; }

.attendanceDetailsJoinDesc, .attendanceDetailsLeaveDesc, .attendanceDetailsInReportTimeDesc {
  margin-top: 5px;
  margin-bottom: 5px; }

.attendanceDetailsJoinDesc, .attendanceDetailsLeaveDesc {
  justify-content: space-between; }

.attendanceDetailsLeaveDesc {
  margin-bottom: 15px; }

.CorrectAnswersText, .totalQuestionsText, .inorrectAnswersText, .unansweredQuestionsText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh; }

.attemptsDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 55%;
  margin-bottom: 1.5vh; }

.attemptsDetails > p {
  font-size: 12px;
  color: #757575;
  margin-left: 6px; }

.attemptsDetailDesc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  min-width: 185px; }

.attemptsDetailDesc p:first-child {
  color: #757575;
  font-size: 12px; }

.attemptsDetailDesc p:last-child {
  color: #333333;
  font-size: 14px; }

.homeworkCardText {
  color: #333333;
  font-size: 12px;
  margin-bottom: 1.5vh;
  font-weight: bold; }

.homeworkCardContainer {
  display: flex;
  margin-top: 2vh;
  padding: 15px;
  background-color: white;
  width: 90%;
  justify-content: space-between; }

.totalQuestionsProgressBar, .CorrectAnswersProgressBar, .inorrectAnswersProgressBar, .unansweredQuestionsProgressBar {
  height: 8px;
  width: 100%;
  position: relative;
  background-color: #eeeeee;
  border-radius: 25px;
  margin-bottom: 1.8vh; }

.totalQuestionsProgressBarInner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2593E2;
  border-radius: 25px; }

.CorrectAnswersProgressBarInner {
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 100%;
  background-color: #01AA93;
  border-radius: 25px; }

.inorrectAnswersProgressBarInner {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  background-color: #FF5744;
  border-radius: 25px; }

.unansweredQuestionsProgressBarInner {
  position: absolute;
  left: 0;
  top: 0;
  width: 10%;
  height: 100%;
  background-color: #A8A7A7;
  border-radius: 25px; }

.courseThumbnail {
  width: 173px;
  height: 165px;
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 8px; }

.homeworkCardProgressContainer p {
  font-size: 12px;
  color: #757575; }

.homeworkCardProgressContainer {
  width: 50%; }

.homeworkCardWrapper {
  width: 47%;
  display: flex;
  flex-direction: column; }

.viewMoreBtn {
  border: none;
  outline: none;
  background-color: #01AA93;
  border-radius: 8px;
  width: 40%;
  min-width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: 10px; }

.courseProgressBarInReportContainer {
  margin-top: 15px; }

.classesInReportCalendarContainer th {
  text-align: left;
  min-width: 150px; }

.classesInReportCalendarContainer td {
  text-align: left; }

.classesInReportCalendarContainer {
  overflow-x: scroll;
  max-width: 100vh; }

.nextYearTableContainer th {
  text-align: left; }

.nextYearTableContainer td {
  text-align: left;
  min-width: 150px; }

.absentClassStatusIndicator {
  width: 27%;
  cursor: pointer;
  max-width: 30px;
  height: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center; }

.activeAbsentClassDenoter {
  width: 27%;
  cursor: pointer;
  max-width: 30px;
  height: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #01AA93;
  color: #FF5744; }

.absentClassDenoter {
  width: 27%;
  cursor: pointer;
  max-width: 30px;
  height: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #FF5744;
  color: #FF5744; }

.classDenoter {
  background-color: #E6F7F5;
  padding-block: 6px;
  padding-inline: 9px; }

.activeClassDenoter {
  background-color: #E6F7F5;
  padding-block: 5px;
  padding-inline: 8px;
  border: 2px solid #01AA93; }

@media screen and (max-width: 1100px) {
  .classesInReportCalendarContainer td, .classesInReportCalendarContainer th {
    padding-left: 0px;
    padding-right: 0px; } }

@media screen and (max-width: 876px) {
  .classStatusIndicator {
    width: 28%;
    height: 30px; }
  .classStatusIndicator p {
    font-size: 10px; }
  .weeksContainer {
    width: 7%;
    margin-top: 55px; } }

@media screen and (max-width: 820px) {
  .batchAndStudentDetailsDesc {
    width: 42%;
    min-width: 172px; } }

@media screen and (max-width: 995px) {
  .classStudentStatus {
    width: 40%;
    min-width: 272px; } }

@media screen and (max-width: 1100px) {
  .classDetailsInReportFlexContainer {
    flex-direction: column;
    align-items: center; }
  .classDetailsInReportContainer {
    width: 50%;
    min-width: 411px; }
  .classDetailsText {
    text-align: center; }
  .attendanceAndHomworkCardContainer {
    display: flex;
    width: 100%; }
  .attemptsDetails {
    width: 70%;
    align-items: center;
    min-width: 141px; }
  .classDetailsInReportTimeDesc {
    width: 90%; } }

@media screen and (max-width: 1021px) {
  .attendanceCardDescAndPieChartContainer {
    flex-direction: column; }
  .attendanceCardText {
    text-align: center;
    margin-bottom: 1.5vh; } }

@media screen and (max-width: 931px) {
  .homeworkCardContainer {
    flex-direction: column;
    position: relative;
    padding-bottom: 50px; }
  .viewMoreBtn {
    position: absolute;
    bottom: 10px; }
  .homeworkCardWrapper {
    width: 90%; }
  .homeworkCardProgressContainer {
    width: 90%; }
  .attemptsDetails {
    width: 100%;
    justify-content: center; }
  .homeworkCardText {
    text-align: center; }
  .homeworkCardWrapper {
    margin-bottom: 2vh; } }

.preview {
    background: white;
    padding: 1rem 1rem 25px 1rem;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    /* background: #fff; */
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

.logo {
    width: 40px;
    margin-left: -40px;
}

.menu {
    margin-left: -4px;
    margin-top: -51.7px;
    font-size: 10px;
}

.layout {
    border: 1px solid black;
    width: 130%;
    background: white;
}

.navbar {
    height: 50%;
}

.registerBtn {
    width: 17%;
    font-size: 10px;
    position: relative;
    left: 200px;
}

.event_logo {
    width: 90px;
    margin-top: 20px;
    border-radius: 10px;
}

.event_title {
    color: #5eb5bf;
    font-size: 25px;
    font-weight: bold;
}

.event_line_1 {
    color: white;
    font-size: 15px;
    margin-bottom: 7px;
}

.event_line_2 {
    color: white;
    font-size: 12px;
    margin-bottom: 7px;
}

.event_line_3 {
    color: white;
    font-size: 12px;
    margin-top: 7px;
    padding-bottom: 10px;
}

.registerBtn2 {
    width: 17%;
    font-size: 10px;
    /* position: relative;
    left: 100px; */
}

.Overview {
    font-weight: bold;
    margin-left: 25px;
    margin-bottom: 25px;
}
.eventListingCard{
    padding-top: 14%;
    padding-right: 10%;
}

.overview_desc {
    color: rgb(50, 50, 50);
    margin-top: 10px;
    margin-left: 25px;
    width: 500px;
}

.PrizeContainer {
    margin-right: -180px;
    margin-left: 25px;
    width: 670px;
    grid-gap: 20px 30px;
    gap: 20px 30px;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
}

.PrizeCard {
    width: 190px;
    height: 140px;
    background-image: url('https://ak.picdn.net/shutterstock/videos/13496246/thumb/1.jpg');
    border-radius: 10px;
}
.PrizeCard-Data {
    width: 190px;
    height: 140px;
    /* background-color: rgb(0, 132, 138); */
    border-radius: 10px;
}

.PrizePos-active {
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
    color: rgb(255, 255, 255)
}

.PrizePos {
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
    color: rgb(156, 156, 156);
}

.PrizePrefix {
    font-size: 14px;
}

.PrizeDesc-active {
    font-size: 13px;
    font-weight: 700;
    margin-left: 15px;
    padding-top: 70px;
    color: white;
}

.PrizeDesc {
    font-size: 13px;
    font-weight: 700;
    margin-left: 15px;
    padding-top: 70px;
    color: #504f4f;
}

.bottomCard {
    width: 190px;
    height: 140px;
    background-color: transparent;
    border: 1px solid rgb(123, 123, 123);
    border-radius: 10px;
    color: white;
    text-align: left;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
}

.bottomCard p {
    position: relative;
    top: 20px;
}

.bottomIcon {
   font-size: 25px;
}

.SpyCard {
    width: 215px;
    height: 100px;
    background: linear-gradient(90deg,#005773 20.31%,#016a8b 95.73%);
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 8px;
}

.SpyPos {
    color: white;
    font-weight: bold;
    font-size: 13px;
}

.SpyDesc {
    color: #017a9f;
    font-size: 13px;
    width: 150px;
}

.SpyContainer {
    margin-right: -180px;
    margin-left: 25px;
    width: 670px;
    grid-gap: 10px 10px;
    gap: 10px 10px;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
}

.spyImg {
    width: 70px;
    position: relative;
    top: -60px;
    left: 127px;
}

.card {
    /* position: relative;
    left: -80px;
    top: 209px; */
    height: 600px;
    width: 300px;
    margin-top: 11rem;
    margin-right: 3rem;
}

.mail {
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-right: 10px;
}

.mainHeader {
    padding-top: 20px;
}

.mainHeader span{
    font-size: 32px;
    color: black;
    font-weight: bold;
}

.editEvent {
    position: relative;
    top: -11.6rem;
    left: 36rem;
}

.selectEventStatus {
    width: '120px';
}

.editEventBtn {
    width: 100px;
    font-size: 0.8rem;
}

.header-desc {
    padding-left: 45px;
    font-size: 15px;
    font-weight: bold;
}

.link-box {
    width: 400px;
    border: 2px solid black;
    margin: 40px 0 40px 45px;
    cursor: pointer;
}

.link-head {
    font-weight: bold;
    padding-left: 5px;
    margin-top: -5px;
}

.link {
    font-size: 10px;
    color: rgb(0, 136, 255);
    text-decoration: underline;
    padding-left: 5px;
}

.redirect {
    font-size: 10px;
    position: relative;
    right: -240px;
    top: 10px;
}

.bottomPart {
    margin: 40px 0 40px 45px;
}

.swtich {
    margin-left: 10px;
    margin-right: 10px;
}

.mainHeader .bottomPart span {
    font-size: 20px;
    color: rgb(113, 113, 113);
    margin-left: 20px;
}

.bottomPart p{
    margin-bottom: 20px;
}
.CardAvatar {
  position: relative;
  top: -30px;
  left: 168px; }

.CardAvatar p {
  position: inherit;
  top: -21px;
  left: 30px;
  font-weight: bold;
  font-size: 12px; }

.ant-card-body {
  background: white;
  padding-bottom: 0px; }

.trending {
  background-color: #d3e7ee;
  width: 30%;
  height: 1%;
  border-radius: 8px;
  font-size: 9px;
  padding: 2px 0 2px 6px;
  color: #103282;
  position: relative;
  left: -15px;
  top: -10px; }

.desc {
  z-index: -1;
  height: 60px; }

.desc h1 {
  position: relative;
  left: -15px;
  top: -35px;
  font-size: 13.5px;
  font-weight: bold; }

.calender {
  position: relative;
  top: -35px;
  left: -15px; }

.calender p {
  position: relative;
  top: -18.5px;
  left: 17px;
  font-size: 12px;
  font-weight: bold; }

.clock {
  position: relative;
  top: -74px;
  left: 80px; }

.clock p {
  position: relative;
  top: -19px;
  left: 19px;
  font-size: 12px;
  font-weight: bold; }

.tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  top: -40px;
  left: -15px; }

.tag {
  background-color: #ffffff;
  border: 0.002px solid #bbbbbb;
  width: auto;
  height: 1%;
  border-radius: 2px;
  font-size: 9px;
  padding: 2px 0 2px 6px;
  color: #989797;
  padding-right: 5px;
  margin-right: 5px; }

h1 {
  margin-bottom: 0; }

.SpeakerPrizes_speakerForm__W4Vvo {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.SpeakerPrizes_addSpeakerProfileForm__3kIBH {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0.5rem 0 0.5rem 2rem; }

.SpeakerPrizes_addSpeakerProfileForm__3kIBH > .SpeakerPrizes_ant-form-item__3RXla {
  background-color: red; }

.SpeakerPrizes_ant-form-item-control__3S9pG {
  line-height: 8px; }

.SpeakerPrizes_ant-form-item-label__1hDa7 {
  background-color: pink;
  text-align: left;
  line-height: normal; }

.SpeakerPrizes_speakerDetail__2lFx5:nth-child(2n) {
  margin-left: 1rem; }

.SpeakerPrizes_titleContainer__1ZMII {
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
  width: 41vw; }

.SpeakerPrizes_soundIcon__3T75Z {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-right: 0.75rem; }

.SpeakerPrizes_prizeForm__2BhIX {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0 0.5rem 2rem;
  flex-basis: 60%; }

.SpeakerPrizes_prizeImage__3lLxF {
  margin: 12px 10px;
  width: 210px; }

.SpeakerPrizes_addSpeakerImageContainer__3CNUt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.SpeakerPrizes_addBtnContainer__2LgeL {
  margin: 1rem 1rem 1rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 39vw; }

.SpeakerPrizes_removeForm__3q5Hj {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.35rem; }
  .SpeakerPrizes_removeForm__3q5Hj:hover {
    background-color: #c9c9c9;
    transition: 200ms ease; }

.SpeakerPrizes_prizeNameWrapper__404Zg {
  border: 1px solid #c9c9c9;
  border-radius: 0.65rem;
  padding: 0 0.5rem; }

.SpeakerPrizes_speaker-form-number__u4pH1 {
  margin-right: 1rem; }

.heading{
    font-weight: 800;
    font-size: 25px;
    display: flex;
    padding: 10px;
    grid-gap: 20px;
    gap: 20px;
    letter-spacing: normal;
    color: black;
}
.removeForm{
    position: absolute;
    top: 1rem;
    right: -2.5rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0.35rem;
}
.removeForm:hover{
        background-color: rgb(201, 201, 201);
        transition:200ms ease;
}
.infoTitle{
    padding-top: 25px;
    padding-left: 30px;
}
.toggleText{
    font-size: 10px;
    color: #808080;
    padding-left: 5px;
    padding-right: 5px;
}
.switchButton{
    background-color: #27995c;
}
.emailComms{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.parent{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 0.5px solid lightgray;
    margin: 20px 0;
    position: relative;
}

.content{
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
}
.content-right{
    width: 80%;
    margin-left: 5%;
}

.large-number{
    font-size: 25px;
    color: #379ff1;
    padding: 20px;
    text-align: center;
    font-weight: 800;
}

.upload{
    color: rgba(128, 128, 128, 0.596);
    padding: 5px 10px;
    border: rgba(128, 128, 128, 0.445) 2px solid;
    outline: none;
    background-color: white;
    margin: 20px 20px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    font-weight: 700;
    font-size: 15px;
    width: 300px;
    letter-spacing: normal;
    cursor: pointer;
}

.v-row{
    display: flex;
    max-width: 70vw;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.v-head{
    color: #8d6cc8;
    letter-spacing: normal;
    font-weight: 700;
}
.v-inp, .v-inp-2{
    width: 50px;
    border: rgba(128, 128, 128, 0.493) 1px solid;
    margin: 10px 0px;
    font-weight: 700;
    padding: 5px 0px;
    padding-left: 5px;
    outline: none;
    border-radius: 2px;
}
.v-inp-2{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    margin: 6px 0px;
}

.plus{
    border-radius: 50%;
    font-size: 30px;
    color: #414bb2;
    background-color: #d9dbf0;
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 50px;
}
.add-more{
    font-size: 15px;
    margin-left: 10px;
    letter-spacing: normal;
}

.certificate-head{
    letter-spacing: normal;
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.certificate{
    width: 600px;
    height: 700px;
    overflow: scroll;
    border: 1px solid;
}

.download-btn{
    display: flex;
    align-items: center;
    letter-spacing: normal;
    color: #2ee183;
    border: #2ee183 solid 2px;
    margin: 10px 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px ;
    font-weight: 600;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
}

.inp-parent{
    letter-spacing: normal;
    border: 1px solid lightgray;
    padding: 5px;
    font-size: 16px;
    width: 300px;
    margin: 20px 0px;
    
}
.utm-inp{
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: none;
    outline: none;
}
.btn-cnt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
}


.back-btn, .continue-btn{
    background-color: #808080;
    color: white;
    border-radius: 5px;
    padding: 10px 40px;
    border: none;
    outline: none;
    font-size: 16px;
}

.continue-btn{
    background-color: #414bb2;
}

.upload-inp{
    background-color: transparent;
    border: none;
    outline: none;
}

.generate{
    padding: 10px 10px;
    color: #414bb2;
    background-color: #414ab262;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}
.anticon-delete {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer; }

.selectClassesContainer .ant-collapse {
  background-color: #FFF;
  margin-top: 25px;
  border: 1px solid rgba(0, 0, 0, 0.3); }

.selectClassesContainer .ant-checkbox-wrapper {
  width: 10%;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 3px; }

.selectClassesContainer .ant-checkbox-inner {
  border: 1px solid #C4C4C4; }

.selectClassesContainer .ant-checkbox-inner:hover {
  border: 1px solid #C4C4C4; }

.selectClassesContainer .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #C4C4C4;
  color: #fff;
  border-color: #C4C4C4; }

.selectClassesContainer .ant-checkbox-wrapper:hover .ant-checkbox-inner, .selectClassesContainer .ant-checkbox:hover .ant-checkbox-inner, .selectClassesContainer .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: none; }

.selectClassesContainer .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' '; }

.selectClassesContainer .ant-checkbox-checked::after {
  border: 1px solid #fff; }

.selectClassesContainer .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px; }

.selectClassesContainer .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 16px;
  padding-right: 40px; }

.ant-collapse {
  border: none;
  border-bottom: 1px solid #EFEFEF; }

.ant-collapse-header {
  background-color: #fff; }

.ant-collapse .ant-collapse-content {
  background-color: #fff; }

.ant-collapse .ant-collapse-item-active .ant-collapse-header {
  background: linear-gradient(269.78deg, rgba(140, 97, 203, 0.1) 0%, rgba(140, 97, 203, 0) 77.35%); }
  .ant-collapse .ant-collapse-item-active .ant-collapse-header h3 {
    color: #8C61CB; }

.ant-collapse .ant-collapse-item-active {
  border: none;
  border: 1px solid #EFEFEF;
  border-radius: 6px 6px 0px 0px; }

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px; }

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding-right: 27px; }

.ant-pagination{
    float: left !important;
}
body {
  margin: 0;
  padding: 0;
  letter-spacing: 1.8px;
  font-family: -apple-system, BlinkMacSystemFont, “Roboto”, “Droid Sans”, “Helvetica Neue”, Helvetica, Arial, sans-serif;
}

input, button {
  /* letter-spacing: 1.8px; */
}

.transformationRow{
  background-color: #f8ff8a59;
}

.mentorRow{
  background-color: rgba(140,97,203, 0.1);
}
.schoolRow{
  background-color: #daead259;
}

.custom_fc_frame {
  left: -3px !important;
  bottom: 10px !important;
}

#launcher-frame{
  min-width: initial;
  max-width: initial;
  width: 60px;
}

.leadStatusWonRow{
  background-color: rgb(217 243 225);
}
.hasRescheduledSessionRow{
  background-color: rgb(247 208 208);
}
.allottedSessionRow{
  background-color: rgb(232, 232, 232);
}
.startedSessionRow{
  background-color: rgb(255, 247, 233);
}
.completedSessionRow{
  background-color: #fffce0;
}
