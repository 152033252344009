.reportProgressBarDetailsOuter{
    width: 100%;
    height: 8px;
    border-radius: 25px;
    background-color: #EEEEEE;
    margin-top: 15px;
    position: relative;
}
.reportProgressBarDetailsInner{
position: absolute;
background-color: #01AA93;
top: 0;
left: 0;
height: 100%;
border-radius: 25px;
}
.courseReportCardImage{
    width: 82px;
    height: 80px;
    border-radius: 8px;
    background-color: #EEEEEE;
    padding: 8px;
}
.reportCardContainer{
    background-color: white;
    padding-block: 30px;
    padding-inline: 25px;

}
.reportProgressDetailsTextContainer{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}
.reportProgressPercentageText{
    display: inline-block;
    margin-left: auto;
    color: black;
    font-weight: 600;
}
.reportProgressDetailsTextContainer p:first-child{
    color: #808080;
}
.reportCardTopSectionContainerLeftContainer{
    display: flex;
    align-items: center;
}
.reportCardTopSectionContainer{
    display: flex;
    justify-content: space-between;
}
.reportProgressDetailsTextContainer>p:nth-child(2){    
    color: red;
    color: black;
    font-weight: 500;
    margin-left: 8px;
    letter-spacing: -0.1px;
}
.reportCardSessionViewReportBtn p{
    margin-left: 5px;
    margin: 0%;
}
.reportCardSessionViewReportBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #01AA93;
    color: white;
    padding-inline: 10px;
    border-radius: 8px;
    border: none;
    font-size: 12px;
    height: 36px;
    outline: none;
}
.reportNameAndDurationText{
    margin-left: 10px;
}
.reportNameAndDurationText h2{
    margin-bottom: 0;
    font-size: 18px;
    color: black;
    margin-bottom: 5%;  
    font-weight: bold;  
}
.reportNameAndDurationText p{
    font-size: 12px;
    color: #808080;    
}
.skeletonLoaderComponent{
    background:
    linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);  
     background-repeat: no-repeat;     
     background-position: -1500px 0, 0 0, 0px 190px, 50px 195px;
     background-size: 1500px 250px, 100% 180px, 100px 100px, 225px 30px;
    padding-block: 30px;
    padding-inline: 25px;
    height: 200px;
    border-radius: 8px;
    animation: loading 2s infinite;  
    margin-block:10px; 
}
@keyframes loading {  
    to {
      background-position: 1500px 0, 0 0, 0 190px, 50px 195px;
    }
  }