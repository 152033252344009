.inputContainer {        
  > textarea {
    border: none;
    position: relative;
    border-bottom: 2px solid #c7c5b9;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: translateY(100%);
  }
}
