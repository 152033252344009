.ImageContainer{
    /* margin-inline: auto; */
    width: 100%;
  }
  .imageSelectorWrapper{
      display: flex;
      justify-content: center;
      margin-inline: auto;
  }
  .imageLabelNumber{
      margin: 0 !important;
      text-align: center;
      margin-bottom: 6px;
  }
  .imageSelectorBtn{
      margin-right: 5px;
      cursor: pointer;
      opacity: 0.75;
  }
  .removeOptionBtn{
      margin-left: 5px;
  }
  .modifiedImageSelectorBtn{
    margin-right: 15px;
    cursor: pointer;
    opacity: 0.75;
  }
  .uploadbtnContainer{
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .imageUploadedPopup{
      position: fixed;
      right: 1%;
      top: 0%;
      width: 200px;
      height: 60px;
      background: white;
      align-items: center;
      display: flex;
      justify-content: center;
      border-bottom: 3px solid #52c41a;
      transition: all 0.3s ease-in-out;
  }
  .imageUploadedPopup p, .hiddenPopup p{
      margin: 0%;
      margin-right: 10px;
  }
  .hiddenPopup{
    position: fixed;
    right: 0%;
    display: flex;
    justify-content: center;
    top: 0%;
    width: 200px;
    align-items: center;
    height: 60px;
    background: #ccc;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }  