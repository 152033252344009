.ant-modal > * {
  letter-spacing: 0px !important;
}
.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A2ABB3FF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
  transition: border-color 0.3s;
  text-align: center;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: #9096980d;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4 ;
    cursor: not-allowed ;
  }
}
.lab-configure-title-container,
.lab-configure-overview-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.2em;
  justify-content: space-between;
}

.upload-overview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}
.lab-info {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  background-color: #fbf9fe;
  border: 2px solid #efe3e3;
  border-radius: 6px;
  padding: 5px 10px;
  &.secondary {
    background: rgba(85, 85, 85, 0.03);
    border: 2px solid #ebebeb;
  }
}
.lab-info-item-label {
  font-size: 12px;
  display: flex;
  color: #4d4e52;
}
.lab-info-item-value {
  color: #111;
  font-size: 14px;
  font-weight: 500;
}
.upload-container {
  box-sizing: border-box;
  padding: 20px;
}
.ant-modal-body {
  padding-top: 10px !important;
}

  .ant-table-body,.ant-table-fixed-right,table {
    border: 1px solid rgba(214, 206, 206, 0.36);
    padding: 10px;
    padding: 0px !important;
    border-radius: 6px;
    thead > tr > th {
      padding: 8px;
      font-size: 12px;
      background-color: transparent !important;
      // line-height: 0;
    }
    .ant-table-tbody > tr > td {
      padding: 4px !important;
      border: 0px;
      font-size: 13px;
    }
  }

.status-pill {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  border-radius: 9px;
  padding: 3px;
  font-size: 13px;
  padding-inline: 12px;
  width: fit-content;
  .dot {
    width: 5px;
    border-radius: 50%;
    height: 5px;
    background-color: currentColor;
  }
  &.new {
    background: #f1f7fe;
    color: #355aa5;
  }
  &.success {
    color: #559e80;
    background: #ecfef2;
  }
  &.default{
    background-color: #F4F4F2;
    color: #969283;
  }
  &.tab{
    border-radius: 5px;
  }
}
.upload-status{
  display: flex;
  align-items: center;
  gap: 6px;
  color: #446FAE;
  font-weight: 500;
  font-size: 13px;

  &.uploaded{
    color: #559E80;
  }
  svg{
    color: currentColor;
    fill: currentColor;
  }
  
}
.handle-btns{
  margin-left: auto;
}
.submit-footer{
  display: flex;
  align-items: center;
  gap: 1em;
}
.dot {
  width: 5px;
  border-radius: 50%;
  height: 5px;
  margin-left: 4px;
  background-color: currentColor;
  &.new{
    background-color: #3ABB40;
  }
  &.old{
    background-color:#6E82AC
  }
  &.large{
    width: 7px;
    height: 7px;
  }
  
  &.align{
    position: relative;
    left: -4px;
  }
}
.upload-overview-table{
  max-height: 200px;
  overflow: auto;
}
.add-lab-text{
  font-size: 12px;
  margin-left: 4px;
  span{
    cursor: pointer;
    color: #00ADE6;
    text-decoration: underline;
  }
}
.ant-modal{
  min-width: 600px !important;
}
.error{
  color: rgb(186, 67, 67);
  font-size: 13px;
}
.ant-modal-header{
  padding: 12px !important;
}
.ant-modal-title{
  font-size: 15px !important;

}
.ant-modal-close-x{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
}
.labconfig-table{
  table > thead > tr{
    line-height: 0;
  }
  table{
    border-bottom: 0px;
    background: #fff;

  }
}