$label-clr: #000;
$primary-clr: #5C55E7;
$secondary-clr: #F2F4F8;
$dark-grey-clr: #44484E;
$medium-grey-clr: #536471;
.component-tab-dlt-btn{
    color: red;
    width: 13px;
    position: absolute;
    right: 5px;

}
.component-dropdown{
    position: absolute;
    bottom: -33px;
    right: 0;
    display: flex;
    gap: 10px;
    color: #F65151;
    background-color: #fff;
    box-shadow: 0px 0px 1px #D0D2D6;
    padding: 7px;
    
}

.component-dropdown-btn{
    border: 0.9px solid #E2E7F2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    .component-dropdown{
        opacity: 0;
    }
    &:hover{
        .component-dropdown{
            opacity: 1;
        }
    }
}
.component-menu-item{
    display: flex;
    color: #F65151;
    .ant-icon-delete{
        margin-left: 0px !important;
    }
}
.iframe-preview-modal{
    letter-spacing: 0;
    .ant-modal-content,.ant-modal-body{
        height: 100%;
    overflow: auto;

    }
    // .ant-modal-body{
    //     height: 600px;
    // }
}
.component-dropdown{
    letter-spacing: 0;
}
.add-session-modal{
    overflow: hidden;
   
    .cs-session-container{
        display: grid;
        grid-template-columns: 0.6fr 200px 130px 1fr 1fr;
        gap: 10px;
    }
    .cs-components-draggable{
        padding-bottom: 10px;
        // remove all the styles of scrollbar and add browser default styles
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }


       


       
    }
    & > *{
        
        letter-spacing: normal;
    }
    .cs-flow{
        --space: 0.8rem;
        & > * + *{
            margin-bottom: var(--space);
        }
    }
    .cs-component-pools{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .componentTags{
            cursor: pointer;
            display: flex;
            justify-content: center;
            gap: 12px;
            align-items: center;
            box-sizing: border-box;
            padding: 8px;
            border-radius: 30px;
            background-color: $secondary-clr;
            color: $dark-grey-clr;
            font-weight: 600;
            font-size: 11px;
            p span{
                color: #111;
                font-weight: bold;
                margin-left: 4px;
            }
            svg{
                color: $primary-clr;
            }
        }
        .cs-component-upload-file{
            display: flex;
            gap: 20px;

        }
}
    .cs-input-container{
        display: flex;
        flex-direction: column;
        
        letter-spacing: 0;
        .ant-select{
            width: 100%;
            min-width: unset;
        }
        
    }
    .cs-label{
        margin-bottom: 10px;
            color: $label-clr;
            font-size: 14px;
            font-weight: 500;
        }
    .cs-ref-content-container{
        // display: flex;
        margin-top: 20px;
        // gap: 10px
        input[type="text"]{
            max-width: 400px;
        }
    }
    .cs-ref-content{
        display: flex;
        flex-direction: column;
        gap: 6px;
    }
    .cs-preview-doc-btn{
        font-size: 13px;
        height: 31px;
        width: fit-content !important;
        border-color: $primary-clr;
        color: $primary-clr;
    }
    .cs-enable-doc-check{
        display: flex;
        gap: 8px;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        color: $medium-grey-clr;
        .ant-checkbox{
            .ant-checkbox-inner{
                border: 1px solid #3C4044;
            }
        }
        .ant-checkbox-checked{
        .ant-checkbox-inner{
            background-color: $primary-clr;
            border-color: $primary-clr;
            &:hover{
                border: none;
            }
        }
    }
        
    }
}