.heading{
    font-weight: 800;
    font-size: 25px;
    display: flex;
    padding: 10px;
    gap: 20px;
    letter-spacing: normal;
    color: black;
}
.removeForm{
    position: absolute;
    top: 1rem;
    right: -2.5rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0.35rem;
}
.removeForm:hover{
        background-color: rgb(201, 201, 201);
        transition:200ms ease;
}
.infoTitle{
    padding-top: 25px;
    padding-left: 30px;
}
.toggleText{
    font-size: 10px;
    color: #808080;
    padding-left: 5px;
    padding-right: 5px;
}
.switchButton{
    background-color: #27995c;
}
.emailComms{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.parent{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 0.5px solid lightgray;
    margin: 20px 0;
    position: relative;
}

.content{
    position: relative;
    width: fit-content;
    display: flex;
}
.content-right{
    width: 80%;
    margin-left: 5%;
}

.large-number{
    font-size: 25px;
    color: #379ff1;
    padding: 20px;
    text-align: center;
    font-weight: 800;
}

.upload{
    color: rgba(128, 128, 128, 0.596);
    padding: 5px 10px;
    border: rgba(128, 128, 128, 0.445) 2px solid;
    outline: none;
    background-color: white;
    margin: 20px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 15px;
    width: 300px;
    letter-spacing: normal;
    cursor: pointer;
}

.v-row{
    display: flex;
    max-width: 70vw;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}
.v-head{
    color: #8d6cc8;
    letter-spacing: normal;
    font-weight: 700;
}
.v-inp, .v-inp-2{
    width: 50px;
    border: rgba(128, 128, 128, 0.493) 1px solid;
    margin: 10px 0px;
    font-weight: 700;
    padding: 5px 0px;
    padding-left: 5px;
    outline: none;
    border-radius: 2px;
}
.v-inp-2{
    width: fit-content;
    border: none;
    margin: 6px 0px;
}

.plus{
    border-radius: 50%;
    font-size: 30px;
    color: #414bb2;
    background-color: #d9dbf0;
    text-align: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 50px;
}
.add-more{
    font-size: 15px;
    margin-left: 10px;
    letter-spacing: normal;
}

.certificate-head{
    letter-spacing: normal;
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.certificate{
    width: 600px;
    height: 700px;
    overflow: scroll;
    border: 1px solid;
}

.download-btn{
    display: flex;
    align-items: center;
    letter-spacing: normal;
    color: #2ee183;
    border: #2ee183 solid 2px;
    margin: 10px 0px;
    width: fit-content;
    padding: 5px 10px ;
    font-weight: 600;
    gap: 10px;
    cursor: pointer;
}

.inp-parent{
    letter-spacing: normal;
    border: 1px solid lightgray;
    padding: 5px;
    font-size: 16px;
    width: 300px;
    margin: 20px 0px;
    
}
.utm-inp{
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: none;
    outline: none;
}
.btn-cnt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
}


.back-btn, .continue-btn{
    background-color: #808080;
    color: white;
    border-radius: 5px;
    padding: 10px 40px;
    border: none;
    outline: none;
    font-size: 16px;
}

.continue-btn{
    background-color: #414bb2;
}

.upload-inp{
    background-color: transparent;
    border: none;
    outline: none;
}

.generate{
    padding: 10px 10px;
    color: #414bb2;
    background-color: #414ab262;
    width: fit-content;
    cursor: pointer;
}