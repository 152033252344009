@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
  letter-spacing: 1.8px;
  font-family: -apple-system, BlinkMacSystemFont, “Roboto”, “Droid Sans”, “Helvetica Neue”, Helvetica, Arial, sans-serif;
}

input, button {
  /* letter-spacing: 1.8px; */
}

.transformationRow{
  background-color: #f8ff8a59;
}

.mentorRow{
  background-color: rgba(140,97,203, 0.1);
}
.schoolRow{
  background-color: #daead259;
}

.custom_fc_frame {
  left: -3px !important;
  bottom: 10px !important;
}

#launcher-frame{
  min-width: initial;
  max-width: initial;
  width: 60px;
}

.leadStatusWonRow{
  background-color: rgb(217 243 225);
}
.hasRescheduledSessionRow{
  background-color: rgb(247 208 208);
}
.allottedSessionRow{
  background-color: rgb(232, 232, 232);
}
.startedSessionRow{
  background-color: rgb(255, 247, 233);
}
.completedSessionRow{
  background-color: #fffce0;
}