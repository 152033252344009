.ant-collapse {
    border: none;
    border-bottom: 1px solid #EFEFEF;
}
.ant-collapse-header {
    background-color: #fff;
}
.ant-collapse .ant-collapse-content {
    background-color: #fff;
}
.ant-collapse .ant-collapse-item-active .ant-collapse-header {
    background: linear-gradient(269.78deg, rgba(140, 97, 203, 0.1) 0%, rgba(140, 97, 203, 0) 77.35%);
    h3 {
        color: #8C61CB;
    }
}
.ant-collapse .ant-collapse-item-active {
    border: none;
    border: 1px solid #EFEFEF;
    border-radius: 6px 6px 0px 0px;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-right: 27px;
}