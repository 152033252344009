.preview {
    background: white;
    padding: 1rem 1rem 25px 1rem;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    /* background: #fff; */
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }

.logo {
    width: 40px;
    margin-left: -40px;
}

.menu {
    margin-left: -4px;
    margin-top: -51.7px;
    font-size: 10px;
}

.layout {
    border: 1px solid black;
    width: 130%;
    background: white;
}

.navbar {
    height: 50%;
}

.registerBtn {
    width: 17%;
    font-size: 10px;
    position: relative;
    left: 200px;
}

.event_logo {
    width: 90px;
    margin-top: 20px;
    border-radius: 10px;
}

.event_title {
    color: #5eb5bf;
    font-size: 25px;
    font-weight: bold;
}

.event_line_1 {
    color: white;
    font-size: 15px;
    margin-bottom: 7px;
}

.event_line_2 {
    color: white;
    font-size: 12px;
    margin-bottom: 7px;
}

.event_line_3 {
    color: white;
    font-size: 12px;
    margin-top: 7px;
    padding-bottom: 10px;
}

.registerBtn2 {
    width: 17%;
    font-size: 10px;
    /* position: relative;
    left: 100px; */
}

.Overview {
    font-weight: bold;
    margin-left: 25px;
    margin-bottom: 25px;
}
.eventListingCard{
    padding-top: 14%;
    padding-right: 10%;
}

.overview_desc {
    color: rgb(50, 50, 50);
    margin-top: 10px;
    margin-left: 25px;
    width: 500px;
}

.PrizeContainer {
    margin-right: -180px;
    margin-left: 25px;
    width: 670px;
    gap: 20px 30px;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
}

.PrizeCard {
    width: 190px;
    height: 140px;
    background-image: url('https://ak.picdn.net/shutterstock/videos/13496246/thumb/1.jpg');
    border-radius: 10px;
}
.PrizeCard-Data {
    width: 190px;
    height: 140px;
    /* background-color: rgb(0, 132, 138); */
    border-radius: 10px;
}

.PrizePos-active {
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
    color: rgb(255, 255, 255)
}

.PrizePos {
    font-size: 20px;
    font-weight: bold;
    margin-left: 8px;
    color: rgb(156, 156, 156);
}

.PrizePrefix {
    font-size: 14px;
}

.PrizeDesc-active {
    font-size: 13px;
    font-weight: 700;
    margin-left: 15px;
    padding-top: 70px;
    color: white;
}

.PrizeDesc {
    font-size: 13px;
    font-weight: 700;
    margin-left: 15px;
    padding-top: 70px;
    color: #504f4f;
}

.bottomCard {
    width: 190px;
    height: 140px;
    background-color: transparent;
    border: 1px solid rgb(123, 123, 123);
    border-radius: 10px;
    color: white;
    text-align: left;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
}

.bottomCard p {
    position: relative;
    top: 20px;
}

.bottomIcon {
   font-size: 25px;
}

.SpyCard {
    width: 215px;
    height: 100px;
    background: linear-gradient(90deg,#005773 20.31%,#016a8b 95.73%);
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 8px;
}

.SpyPos {
    color: white;
    font-weight: bold;
    font-size: 13px;
}

.SpyDesc {
    color: #017a9f;
    font-size: 13px;
    width: 150px;
}

.SpyContainer {
    margin-right: -180px;
    margin-left: 25px;
    width: 670px;
    gap: 10px 10px;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
}

.spyImg {
    width: 70px;
    position: relative;
    top: -60px;
    left: 127px;
}

.card {
    /* position: relative;
    left: -80px;
    top: 209px; */
    height: 600px;
    width: 300px;
    margin-top: 11rem;
    margin-right: 3rem;
}

.mail {
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-right: 10px;
}

.mainHeader {
    padding-top: 20px;
}

.mainHeader span{
    font-size: 32px;
    color: black;
    font-weight: bold;
}

.editEvent {
    position: relative;
    top: -11.6rem;
    left: 36rem;
}

.selectEventStatus {
    width: '120px';
}

.editEventBtn {
    width: 100px;
    font-size: 0.8rem;
}

.header-desc {
    padding-left: 45px;
    font-size: 15px;
    font-weight: bold;
}

.link-box {
    width: 400px;
    border: 2px solid black;
    margin: 40px 0 40px 45px;
    cursor: pointer;
}

.link-head {
    font-weight: bold;
    padding-left: 5px;
    margin-top: -5px;
}

.link {
    font-size: 10px;
    color: rgb(0, 136, 255);
    text-decoration: underline;
    padding-left: 5px;
}

.redirect {
    font-size: 10px;
    position: relative;
    right: -240px;
    top: 10px;
}

.bottomPart {
    margin: 40px 0 40px 45px;
}

.swtich {
    margin-left: 10px;
    margin-right: 10px;
}

.mainHeader .bottomPart span {
    font-size: 20px;
    color: rgb(113, 113, 113);
    margin-left: 20px;
}

.bottomPart p{
    margin-bottom: 20px;
}