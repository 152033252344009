.leftbarContainer{
    height: 100vh;
    width: 18vw;
    padding: 1%;
}
body{
    overflow-x: hidden;
}
.studentProfileContainer{
    display: flex;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    max-width: 100vw;
    overflow: hidden
}
::-webkit-scrollbar {
    width: 6px;
  }
::-webkit-scrollbar-thumb {
    background: #CCCCCC;
    border-radius: 25px;
  }
.ProfileContainer{
    padding: 2%;
    width: calc(100vw - 18vw);
    background-color: #fcfafaec;    
    overflow-y: auto;
    overflow-x: hidden;
}
.profileTopSection{
    background-color: white;
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 196.75px;
}
.profileTopSectionWrapper{
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
}
.studentName{
    color: black;
    font-weight: bold;
    font-size: 20px
}
.statusText{
    text-transform: uppercase;
    color: #01AA93;
    background-color: #E6F7F5;
    padding: 2px;
    font-size: 12px;
}
.statusWrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
    width: 100%;
}
.parentNameDetails, .parentEmailDetails, .parentPhoneDetails{
    display: flex;
}
.parentNameDetails h4, .parentEmailDetails h4, .parentPhoneDetails h4{
    font-size: 12px;
    margin-top: 2px;
    color: #424242;
}
.parentPhoneDetails{
    width: 120%;
}
.parentNameDetails p, .parentEmailDetails p, .parentPhoneDetails p{
    font-size: 10px;
    letter-spacing: -0.1px;
    color: #757575;
}
.parentNameContainer , .parentEmailContainer , .parentPhoneContainer {
margin-left: 5%;
}
.parentPhoneContainer h4{
    letter-spacing: -0.005px;
}
.parentEmailDetails{
    width: max-content;
}
.userParentDetailsContainer{
    display: flex;
    width: 45%;
}
.userAccountDetailsContainer{
    display: flex;
    flex-direction: column; 
    margin-left: 5%;
}
.userImageAndDetailsContainer{
    display: flex;
    height: 100%;
    width: 50%;
}
.userParentDetailsWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.accountCreationDataText{
    font-size: 12px;
    color: #757575;
    word-spacing: -2px;
}
.parentNameDetails{
    width: 40%;
}
.parentPhoneDetails{
    width: 40%;
}
.userParentDetailsflexWrapper{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
}
.profileTabListContainer{
    display: flex;
    list-style-type: none;
    color: #A8A7A7;
    width: 30%;
    justify-content: space-between;
    margin: 0%;
    margin-top: 25px;
    height: 4.5vh;
    padding-left: 0%;
}
.profileTabListContainer li{
cursor: pointer;
}

.active{
    color: #01AA93;
    position: relative;
}
.active::after{
    content: '';
    position: absolute;
    bottom: 0%;
    background-color: #01AA93;
    left: 0%;
    width: 120%;
    height: 0px;
    transform: translateX(-10%);
    border-top: 4px solid #01AA93;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.seperator{
    margin: 0%;
    border: 1px solid #eeeeee;
}
.borderBottom{
position: absolute;
}
.coursesCardContainer{
    margin-top: 15px;
}
.coursesCardContainer>h3{
    font-weight: bold;
    letter-spacing: -0.05px;
    color: #212121;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 2%;
}
.cardsContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.preloaderPostSales{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media screen and (max-width: 987px){
    .profileTabListContainer{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 885px){
    .userImageAndDetailsContainer{
        flex-direction: column;
        display: flex;
        align-items: center;
    }
    .profileTopSection{
        height: auto;
        max-height: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .userParentDetailsflexWrapper{
        display: flex;
        flex-direction: column;
    }
    .parentNameDetails{
        width: 100%;
    }
    .parentPhoneDetails{
        width: 100%;
    }
    .parentEmailDetails{
        width: 100%;
    }
    .studentName{
        margin-top: 1vh;
    }
    .profileTabListContainer{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .cardsContainer{
        display: flex;
        flex-direction: column;
        width: 80%;
        align-items: center;
        position: relative;
    }
    .coursesCardContainer>h3{
        text-align: center;
    }
    .coursesCardContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width: 585px){
    .studentName{
        margin-top: 1vh;
        font-size: 18px;
    }
    .accountCreationDataText{
        word-spacing: -2px;
        font-size: 10px;
    }
    .statusWrapper{
        display: flex;
        align-items: center;
    }
    .statusWrapper{
        font-size: 11px;
        width: 90%;
    }
    .statusText{
        font-size: 12px;
    }
    .profileTopSectionWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .parentsDetailsText{
        text-align: center;
    }
    .statusWrapper{
        margin-top: 2vh;
        margin-bottom: 2vh;
        width: 100%;
    }
    
    .userImageAndDetailsContainer{
        width: 60%;
    }
    .studentName{
        font-size: 16px;
    }
}
@media screen and (max-width: 450px){
  .userParentDetailsContainer{
     width: auto
    }  
    .profileTopSectionWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 500px){
    .profileTabListContainer li{
        font-size: 12px;
    }
  }