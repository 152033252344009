// @import '../../scss/size.scss';
.selectClassesContainer{
    .ant-collapse {
    background-color: #FFF;
    margin-top: 25px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.ant-collapse-content {
    // background-color: #e5e5e5;
}
.ant-checkbox-wrapper {
    width: 10%;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    height: fit-content;
    margin-left: 3px;
}
.ant-checkbox-inner {
    border: 1px solid #C4C4C4;
}
.ant-checkbox-inner:hover {
    border: 1px solid #C4C4C4;
}
.ant-checkbox-checked .ant-checkbox-inner  {
    background-color: #C4C4C4;
    color: #fff;
    border-color: #C4C4C4;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: none; 
}
.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}
.ant-checkbox-checked::after {
    border: 1px solid #fff;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 16px;
    padding-right: 40px;
}
}