$accentColor: #8C61CB;
$tekie-grey: #666666;

:root {
	--fc-today-bg-color: rgba(0,0,0,0.023);
	--fc-border-color: #F3F3F3;
	--fc-now-indicator-color: #8C61CB;
}

.mentor-dashboard-calender-loading-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999;
	padding: 12px 20px;
}
.mentor-dashboard-calender-loading {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 450;
  color: $accentColor;
}

.prev-dates{
	cursor: not-allowed;
}
.normal-dates{
	cursor: pointer;
}
#mentor-dashboard-calender-container {
	height: 100%;
	width: 100%;
	letter-spacing: 0.4px;

	& a:hover {
		color: #605959;
	}
}

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  &.show {
    opacity: 0.6;
    pointer-events: initial;
  }
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease-in-out;
}

.loading-bar-container {
  width: 100%;
  background: $accentColor;
  height: 5px;
  div {
    height: 100%;
    width: 0%;
    position: relative;
    left: 0;
    background: white;
    animation:indeterminate-scale-animation 1.5s linear infinite;
  }
}

@keyframes indeterminate-scale-animation {

  0% {
    left: 0%;
    width: 0%;
  }
  20% {
    left: 0%;
    width: 30%;
    animation-timing-function: cubic-bezier(.152313,.196432,.648374,1.004315);
  }
  60% {
    left: 70%;
    animation-timing-function: cubic-bezier(.257759,.003163,.211762,1.381790);
    width: 30%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

#mentor-dashboard-calender-container, 
#demand-calendar-dashboard-container,
#demand-calendar-dashboard-container-week,
#supply-calendar-dashboard-container
 {
	::-webkit-scrollbar {
	  width: 4px;
	}
	/* Track */
	::-webkit-scrollbar-track {
	  width: 2px;
	  border-radius: 100px;
	}
	 
	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #CCCCCC; 
	  border-radius: 100px;
	}
}

.fc-timegrid-event {
	background: none !important;
	border: none !important;
}

#demand-calendar-dashboard-container{
	& .fc .fc-popover{
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%);
		width: 90% !important;
	}
	& .fc-timegrid-event-harness{
		width: 100px !important;
	}
}

#demand-calendar-dashboard-container-week{
	& .fc .fc-popover{
		top: 50% !important;
		left: 50% !important;
		width: 90% !important;
		transform: translate(-50%, -50%);
	}
}

.event-container {
	width: 100%;
	height: 100%;
	letter-spacing: 0px;
	overflow: hidden; 
	border-radius: 6px;
	padding: 2px 2px 2px 4px;
	display: flex; 
	align-items: flex-start;
	justify-content: space-between;
	border-width: 2px;
	border-style: solid;
	cursor: pointer;
	border-color: transparent;
	color: #fff;
	transition: all 0.4s ease-in;
	& > .event-details {
		display: inline-flex;
		width: 100%;
		overflow: hidden;
	}
}

.event-empty-slot{
    background: #FAF6FF !important;
    box-sizing: border-box;
    border-radius: 4px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	&::after{
		content: '+';
		width: 20px;
		color: #8C61CB;
		font-size: 30px;
		font-weight: 200;
		display: none;
	}
	&:hover{
		box-shadow: 0px 4px 20px rgba(14, 0, 35, 0.1);
		border: 1px dashed #8C61CB;
		&::after{
			display: block;
		}
	}
}

.event-date-indicator {
	text-transform: lowercase;
	margin-right: 3px;
	letter-spacing: -.5px;
}

.event-title {
	font-weight: 600;
	display: block;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-transform: capitalize;
}

.event-icon {
	display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    position: relative;
	fill: #FFF;
    top: 2px;

	svg {
		margin-right: 2px;
	}
}

.event-noOfStudent-indicator {
	border-radius: 4px;
	letter-spacing: -.5px;
	padding: 2px 3px;
	min-width: 20px;
	text-align: center;
}

.fc .fc-toolbar.fc-header-toolbar {
	margin-bottom: 12px;
	flex-wrap: wrap;
}

.fc-toolbar-title {
	font-size: 18px !important;
	display: inline-block;
	vertical-align: top;
}

.fc-view-harness {
	background-color: white;
	border-radius: 12px;
	overflow: hidden;
	font-family: 'Inter';
}

.fc-col-header-cell-cushion, .fc-list-day-cushion {
	color: #605959;
	font-weight: 500;
	white-space: pre-line;
	
	& > a {
		color: #605959;
		font-weight: 600;
		letter-spacing: 1px;

		&:hover {
			color: #605959 !important;
		}
	}
}

.fc-list-day-cushion  {
	background-color: #fff !important;
}

.fc-daygrid-day:nth-child(even) {
	background: #fbf9ffcb !important
}

.fc-daygrid-day-number {
	color: #B4B4B4;
	width: 30px;
    height: 30px;
    text-align: center;
}

.fc-daygrid-day-frame  {
	// display: flex;
	// flex-direction: column-reverse;
}

.fc-scrollgrid-sync-inner:hover {
	.fc-daygrid-day-number {
		background-color: #FAF7FF;
		color: $accentColor !important;
		border-radius: 8px;
		padding: 5px 6px;
		font-weight: 600;
	}
}

.fc-toolbar-chunk {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

.fc {
	direction: ltr;
	text-align: left;
	}

.fullcalendar-timeGrid-header-weekday {
	font-size: 14px;
	color: #605959;
	font-weight: 500;
	margin-bottom: 2px;
	letter-spacing: 1px;
}

.fc-day-today .fullcalendar-timeGrid-header-weekday{
	color: $accentColor;
}

.fc-day-today .fullcalendar-timeGrid-header-date{
	background: $accentColor;
	color: #fff;
}

.fullcalendar-timeGrid-header-date {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0px;
    width: fit-content;
    margin-left: auto;
    border-radius: 8px;
    padding: 2px 4px;
	min-width: 26px;
}
	
.fc table {
	border-collapse: collapse;
	border-spacing: 0;
	}
	
html .fc,
.fc table {
	font-size: 1em;
	font-family: "Inter",Helvetica;

	}
	
.fc td,
.fc th {
	padding: 0;
	vertical-align: top;
}

.fc th {
	text-align: right;
	padding: 14px 8px 4px !important;
	color: $tekie-grey;
	vertical-align: top;
}

.fc-list-day th {
	padding: 0px !important;
} 



/* Header
------------------------------------------------------------------------*/

.fc-header td {
	white-space: nowrap;
	padding: 15px 10px 0px;
}

.fc-header-left {
	width: 25%;
	text-align: left;
}
	
.fc-header-center {
	text-align: center;
	}
	
.fc-header-right {
	width: 25%;
	text-align: right;
	}
	
	
.fc-header-title h2 {
	margin-top: 0;
	white-space: nowrap;
	font-size: 32px;
    font-weight: 100;
    margin-bottom: 10px;
}
	
.fc .fc-header-space {
	padding-left: 10px;
	}
	
.fc-header .fc-button {
	margin-bottom: 1em;
	vertical-align: top;
}
	
/* buttons edges butting together */

.fc-header .fc-button {
	margin-right: -1px;
	}
	
.fc-header .fc-corner-right,  /* non-theme */
.fc-header .ui-corner-right { /* theme */
	margin-right: 0; /* back to normal */
	}
	
/* button layering (for border precedence) */
	
.fc-header .fc-state-hover,
.fc-header .ui-state-hover {
	z-index: 2;
	}
	
.fc-header .fc-state-down {
	z-index: 3;
	}

.fc-header .fc-state-active,
.fc-header .ui-state-active {
	z-index: 4;
	}
	
	
	
/* Content
------------------------------------------------------------------------*/
	
.fc-content {
	clear: both;
	zoom: 1; /* for IE7, gives accurate coordinates for [un]freezeContentHeight */
	}
	
.fc-view {
	width: 100%;
	overflow: hidden;
	}
	
	

/* Cell Styles
------------------------------------------------------------------------*/

    /* <th>, usually */
.fc-widget-content {  /* <td>, usually */
	border: 1px solid #e5e5e5;
	}
.fc-widget-header{
    border-bottom: 1px solid #EEE; 
}	
.fc-state-highlight { /* <td> today cell */ /* TODO: add .fc-today to <th> */
	/* background: #fcf8e3; */
}

.fc-day-today > div > div .fc-daygrid-day-number{
	background-color: #8C61CB;
	color: #FFFFFF;
	border-radius: 8px;
	padding: 4px 6px;
	text-align: center;
}

.fc-daygrid-day-number {
	letter-spacing: 0px;
	margin: 4px 8px;
}

.fc-state-highlight > div > div.fc-daygrid-day-number{
    background-color: #ff3b30;
    color: #FFFFFF;
    border-radius: 50%;
    margin: 4px;
}
	
.fc-cell-overlay { /* semi-transparent rectangle while dragging */
	background: #bce8f1;
	opacity: .3;
	filter: alpha(opacity=30); /* for IE */
	}

.fc-daygrid-event {
	border-radius: 6px;
	margin-top: 0px !important;
}


.fc-daygrid-more-link {
	color: $accentColor !important;
	letter-spacing: 0px;
	font-weight: 600;
	
	&:hover {
		color: $accentColor !important;
	}
}

/* Buttons
------------------------------------------------------------------------*/
.fc-button-group {
	background-color: #eee;
	border-radius: 8px;
}
.fc-button {
	font-family: 'Inter' !important;
	font-weight: 500 !important;
	background-color: #EEE !important;
	border: none !important;
	border-radius: 10px !important;
	position: relative;
	display: inline-block;
	padding: .5em 1.2em !important;
	overflow: hidden;
	white-space: nowrap;
	text-transform: capitalize !important;
	cursor: pointer;
	color: #666666 !important;
	letter-spacing: 0px !important;
	margin: 4px !important;

	&:focus {
		box-shadow: none !important;
	}
}
.fc-button-active {
    transition: all .3s;
	background-color: #fff !important;
	color: $accentColor !important;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11) !important;
}

.fc-prev-button, .fc-next-button  {
	margin: 0  !important;
	background-color: transparent !important;
	border: none !important;
	padding: 0.4em 0.65em !important;
	
	&:focus {
		border: none !important;
		box-shadow: none !important;
	}
	
	&:hover {
		& > .fc-icon {
			transition: all .3s;
			color: #605959;
		}
	}
	& > .fc-icon {
		transition: all .3s;
		color: #CBD0DF;
	}
}

.fc-today-button {
	margin-left: 18px !important;
	background-color: #FAF6FF !important;
	color: #8C61CB !important;
}

@media screen and (max-width: 700px) {
	.fc .fc-toolbar {
		justify-content: center;
	}
	.fc-toolbar-chunk {
		margin-bottom: 8px !important;
	}
	.fc-today-button {
		display: none !important;
	}
}

	
.fc-state-default { /* non-theme */
	border: 1px solid;
	}

.fc-state-default.fc-corner-left { /* non-theme */
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	}

.fc-state-default.fc-corner-right { /* non-theme */
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	}

/*
	Our default prev/next buttons use HTML entities like &lsaquo; &rsaquo; &laquo; &raquo;
	and we'll try to make them look good cross-browser.
*/

.fc-text-arrow {
	margin: 0 .4em;
	font-size: 2em;
	line-height: 23px;
	vertical-align: baseline; /* for IE7 */
	}

.fc-button-prev .fc-text-arrow,
.fc-button-next .fc-text-arrow { /* for &lsaquo; &rsaquo; */
	font-weight: bold;
	}
	
/* icon (for jquery ui) */
	
.fc-button .fc-icon-wrap {
	position: relative;
	float: left;
	top: 50%;
	}
	
.fc-button .ui-icon {
	position: relative;
	float: left;
	margin-top: -50%;
	*margin-top: 0;
	*top: -50%;
	}


.fc-state-default {
	border-color: #ff3b30;
	color: #ff3b30;	
}
.fc-button-month.fc-state-default, .fc-button-agendaWeek.fc-state-default, .fc-button-agendaDay.fc-state-default{
    min-width: 67px;
	text-align: center;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #FFE3E3;
	}

	

/* Global Event Styles
------------------------------------------------------------------------*/

.fc-event-container > * {
	z-index: 8;
	}

.fc-event-container > .ui-draggable-dragging,
.fc-event-container > .ui-resizable-resizing {
	z-index: 9;
	}
	 
.fc-event {
	border: 1px solid #FFF; /* default BORDER color */
	background-color: #FFF; /* default BACKGROUND color */
	color: #919191;               /* default TEXT color */
	font-size: 12px;
	cursor: default;
}
.fc-event.chill{
    background-color: #f3dcf8;
}
.fc-event.info{
    background-color: #c6ebfe;
}
.fc-event.important{
    background-color: #FFBEBE;
}
.fc-event.success{
    background-color: #BEFFBF;
}
.fc-event:hover{
    opacity: 0.7;
}
a.fc-event {
	text-decoration: none;
	}
	
a.fc-event,
.fc-event-draggable {
	cursor: pointer;
	}
	
.fc-rtl .fc-event {
	text-align: right;
	}

.fc-event-inner {
	width: 100%;
	height: 100%;
	overflow: hidden;
	line-height: 15px;
	}
	
.fc-event-time,
.fc-event-title {
	padding: 0 1px;
	}
	
.fc .ui-resizable-handle {
	display: block;
	position: absolute;
	z-index: 99999;
	overflow: hidden; /* hacky spaces (IE6/7) */
	font-size: 300%;  /* */
	line-height: 50%; /* */
	}
	
	
	
/* Horizontal Events
------------------------------------------------------------------------*/

.fc-event-hori {
	border-width: 1px 0;
	margin-bottom: 1px;
	}

.fc-ltr .fc-event-hori.fc-event-start,
.fc-rtl .fc-event-hori.fc-event-end {
	border-left-width: 1px;
	/*
border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
*/
	}

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
	border-right-width: 1px;
	/*
border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
*/
	}
	
/* resizable */
	
.fc-event-hori .ui-resizable-e {
	top: 0           !important; /* importants override pre jquery ui 1.7 styles */
	right: -3px      !important;
	width: 7px       !important;
	height: 100%     !important;
	cursor: e-resize;
	}
	
.fc-event-hori .ui-resizable-w {
	top: 0           !important;
	left: -3px       !important;
	width: 7px       !important;
	height: 100%     !important;
	cursor: w-resize;
	}
	
.fc-event-hori .ui-resizable-handle {
	_padding-bottom: 14px; /* IE6 had 0 height */
	}
	
	
	
/* Reusable Separate-border Table
------------------------------------------------------------*/

table.fc-border-separate {
	border-collapse: separate;
	}
	
.fc-border-separate th,
.fc-border-separate td {
	border-width: 1px 0 0 1px;
	}
	
.fc-border-separate th.fc-last,
.fc-border-separate td.fc-last {
	border-right-width: 1px;
	}
	

// .fc-border-separate tr.fc-last td {
	
// }
.fc-border-separate .fc-week .fc-first{
    border-left: 0;
}
.fc-border-separate .fc-week .fc-last{
    border-right: 0;
}
.fc-border-separate tr.fc-last th{
    border-bottom-width: 1px;
    border-color: #cdcdcd;
    font-size: 16px;
    font-weight: 300;
	line-height: 30px;
}
.fc-border-separate tbody tr.fc-first td,
.fc-border-separate tbody tr.fc-first th {
	border-top-width: 0;
	}
	
	

/* Month View, Basic Week View, Basic Day View
------------------------------------------------------------------------*/

.fc-grid th {
	text-align: center;
	}

.fc .fc-week-number {
	width: 22px;
	text-align: center;
	}

.fc .fc-week-number div {
	padding: 0 2px;
	}
	
.fc-grid .fc-day-number {
	float: right;
	padding: 0 2px;
	}
	
.fc-grid .fc-other-month .fc-day-number {
	opacity: 0.3;
	filter: alpha(opacity=30); /* for IE */
	/* opacity with small font can sometimes look too faded
	   might want to set the 'color' property instead
	   making day-numbers bold also fixes the problem */
	}
	
.fc-grid .fc-day-content {
	clear: both;
	padding: 2px 2px 1px; /* distance between events and day edges */
	}
	
/* event styles */
	
.fc-grid .fc-event-time {
	font-weight: bold;
	}
	
/* right-to-left */
	
.fc-rtl .fc-grid .fc-day-number {
	float: left;
	}
	
.fc-rtl .fc-grid .fc-event-time {
	float: right;
	}
	
	

/* Agenda Week View, Agenda Day View
------------------------------------------------------------------------*/

.fc-agenda table {
	border-collapse: separate;
	}
	
.fc-agenda-days th {
	text-align: center;
	}
	
.fc-agenda .fc-agenda-axis {
	width: 50px;
	padding: 0 4px;
	vertical-align: middle;
	text-align: right;
	white-space: nowrap;
	font-weight: normal;
	}

.fc-agenda .fc-week-number {
	font-weight: bold;
	}
	
.fc-agenda .fc-day-content {
	padding: 2px 2px 1px;
	}
	
/* make axis border take precedence */
	
.fc-agenda-days .fc-agenda-axis {
	border-right-width: 1px;
	}
	
.fc-agenda-days .fc-col0 {
	border-left-width: 0;
	}
	
/* all-day area */
	
.fc-agenda-allday th {
	border-width: 0 1px;
	}
	
.fc-agenda-allday .fc-day-content {
	min-height: 34px; /* TODO: doesnt work well in quirksmode */
	_height: 34px;
	}
	
/* divider (between all-day and slots) */
	
.fc-agenda-divider-inner {
	height: 2px;
	overflow: hidden;
	}
	
.fc-widget-header .fc-agenda-divider-inner {
	background: #eee;
	}
	
/* slot rows */
	
.fc-agenda-slots th {
	border-width: 1px 1px 0;
	}
	
.fc-agenda-slots td {
	border-width: 1px 0 0;
	background: none;
	}
	
.fc-agenda-slots td div {
	height: 20px;
	}
	
.fc-agenda-slots tr.fc-slot0 th,
.fc-agenda-slots tr.fc-slot0 td {
	border-top-width: 0;
	}
	
.fc-agenda-slots tr.fc-minor th.ui-widget-header {
	*border-top-style: solid; /* doesn't work with background in IE6/7 */
	}
	


/* Vertical Events
------------------------------------------------------------------------*/

.fc-event-vert {
	border-width: 0 1px;
	}

.fc-event-vert.fc-event-start {
	border-top-width: 1px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	}

.fc-event-vert.fc-event-end {
	border-bottom-width: 1px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	}
	
.fc-event-vert .fc-event-time {
	white-space: nowrap;
	font-size: 10px;
	}

.fc-event-vert .fc-event-inner {
	position: relative;
	z-index: 2;
	}
	
.fc-event-vert .fc-event-bg { /* makes the event lighter w/ a semi-transparent overlay  */
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: .25;
	filter: alpha(opacity=25);
	}
	
.fc .ui-draggable-dragging .fc-event-bg, /* TODO: something nicer like .fc-opacity */
.fc-select-helper .fc-event-bg {
	display: none\9; /* for IE6/7/8. nested opacity filters while dragging don't work */
	}
	
/* resizable */
	
.fc-event-vert .ui-resizable-s {
	bottom: 0        !important; /* importants override pre jquery ui 1.7 styles */
	width: 100%      !important;
	height: 8px      !important;
	overflow: hidden !important;
	line-height: 8px !important;
	font-size: 11px  !important;
	font-family: monospace;
	text-align: center;
	cursor: s-resize;
	}
	
.fc-agenda .ui-resizable-resizing { /* TODO: better selector */
	_overflow: hidden;
	}
	
thead tr.fc-first{
    background-color: #f7f7f7;
}
table.fc-header{
    background-color: #FFFFFF;
    border-radius: 6px 6px 0 0;
}

.fc-week .fc-day > div .fc-day-number{
    font-size: 15px;
    margin: 2px;
    min-width: 19px;
    padding: 6px;
    text-align: center;
}
.fc-sun, .fc-sat{
    color: #b8b8b8;
}

.fc-week .fc-day:hover .fc-day-number{
    background-color: #FFE8EA;
    border-radius: 50%;
    color: #D34B57;
    transition: background-color 0.2s;
}
.fc-week .fc-day.fc-state-highlight:hover .fc-day-number{
    background-color:  #ff3b30;
}
.fc-view-agendaDay thead tr.fc-first .fc-widget-header{
    text-align: right;
    padding-right: 10px;
}