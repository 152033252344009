@charset "UTF-8";
.upload-student-tab-container {
  position: relative;
  letter-spacing: initial;
  --status-upload-bg: #ecfef4;
  --status-upload-icon: #34d399;
  --status-upload-text: #12644e;
  --status-error-bg: #fef2f2;
  --status-error-icon: #f87171;
  --status-error-text: #aa4240;
  --status-left-bg: #fffbec;
  --status-left-icon: #fbbf24;
  --status-left-text: #b6902e; }
  .upload-student-tab-container .ant-btn {
    font-size: 13px;
    border: 1px solid #00000014;
    box-shadow: none; }
    .upload-student-tab-container .ant-btn span {
      margin-left: unset; }
    .upload-student-tab-container .ant-btn[disabled] {
      background: #ebeaeb;
      color: #6f706ff2; }
  .upload-student-tab-container .upload-student-nav-btns {
    display: flex;
    gap: 0.8em; }
  .upload-student-tab-container .turncate {
    width: 16ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .upload-student-tab-container .upload-file-name {
    font-size: 10px;
    text-transform: lowercase;
    font-weight: 400;
    color: #7d7d7d; }
  .upload-student-tab-container .default-btn {
    --text-clr: #24292f;
    --icon-clr: #111;
    font-weight: 500;
    font-size: 12px;
    background-color: #f6f8fa; }
    .upload-student-tab-container .default-btn span {
      margin: 0; }
    .upload-student-tab-container .default-btn p {
      color: #111; }
    .upload-student-tab-container .default-btn svg {
      width: 14px !important;
      height: 14px !important;
      color: var(--icon-clr); }
    .upload-student-tab-container .default-btn:hover, .upload-student-tab-container .default-btn:focus {
      color: inherit;
      box-shadow: none; }
  .upload-student-tab-container .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1.2em;
    margin-inline: auto;
    text-align: center;
    padding: 2em;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A2ABB3FF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s; }
    .upload-student-tab-container .upload-container:hover {
      transition: all 0.2s ease-in-out;
      background-color: #9096980d; }
    .upload-student-tab-container .upload-container h3 {
      font-size: 1.4em; }
    .upload-student-tab-container .upload-container .uploaded-text {
      color: #22222296; }
    .upload-student-tab-container .upload-container .upload-drag-info {
      display: flex;
      flex-direction: column;
      gap: 0.5em; }
    .upload-student-tab-container .upload-container > * + * {
      margin-top: 10px; }
    .upload-student-tab-container .upload-container .anticon {
      color: #3fa9ff;
      font-size: 48px; }
  .upload-student-tab-container .file-name-container {
    color: #111;
    display: flex;
    width: fit-content;
    gap: 1em;
    align-items: center;
    border: 1px solid #e8e7e8;
    border-radius: 10px;
    padding: 1em; }
    .upload-student-tab-container .file-name-container .ant-spin {
      margin-right: 10px; }
  .upload-student-tab-container .remove-file {
    color: #ef4444;
    letter-spacing: 0.82px;
    font-size: 12px;
    cursor: pointer; }
  .upload-student-tab-container .file-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em; }
  .upload-student-tab-container .file-name {
    font-weight: 500;
    font-size: 13px;
    width: 18ch;
    letter-spacing: 0.1em; }
  .upload-student-tab-container .select-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1em; }
    .upload-student-tab-container .select-container .select-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em; }
      .upload-student-tab-container .select-container .select-wrapper > div:nth-child(1) {
        letter-spacing: 1.2px;
        flex-basis: 27%;
        color: #6e7982; }
      .upload-student-tab-container .select-container .select-wrapper .ant-select {
        flex-grow: 1;
        height: 100%; }
  .upload-student-tab-container .ant-form-explain {
    margin-top: 3px;
    font-size: 12px;
    letter-spacing: 0.3px; }
  .upload-student-tab-container .upload-student-nav {
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em; }
    .upload-student-tab-container .upload-student-nav .nav-button-container {
      margin-left: auto;
      display: flex;
      gap: 1em; }
    .upload-student-tab-container .upload-student-nav .ant-icon {
      width: unset; }
  .upload-student-tab-container .placeholder-header {
    width: 100%;
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    letter-spacing: 1px;
    color: #191616;
    font-weight: 500;
    margin-bottom: 20px; }
    .upload-student-tab-container .placeholder-header .file-column {
      flex-basis: 27%; }
    .upload-student-tab-container .placeholder-header .select-column {
      flex-grow: 1; }
  .upload-student-tab-container .drop-overlay {
    backdrop-filter: blur(7px);
    position: fixed !important;
    inset: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    background: #000000d4;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12vw;
    color: #ffff; }
    .upload-student-tab-container .drop-overlay p {
      pointer-events: none; }
  .upload-student-tab-container .data-status-upload {
    color: #34a2d3;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    gap: 0.5em;
    margin-left: auto; }
  .upload-student-tab-container .data-status-uploading-container {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.8px;
    gap: 0.8em; }
  .upload-student-tab-container .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em; }
    .upload-student-tab-container .ant-btn svg {
      display: block; }
  .upload-student-tab-container .status {
    color: #111;
    display: flex;
    padding: 0.5em;
    padding-inline: 0.7em;
    border-radius: 50px;
    align-items: center;
    cursor: pointer;
    gap: 0.6em;
    border: 1px solid #00000014;
    text-transform: capitalize; }
    .upload-student-tab-container .status p {
      letter-spacing: 0.3px; }
    .upload-student-tab-container .status .icon {
      width: 8px;
      height: 8px;
      border-radius: 2px; }
    .upload-student-tab-container .status[data-type="upload"] .icon {
      background: var(--status-upload-icon); }
    .upload-student-tab-container .status[data-type="error"] .icon {
      background: var(--status-error-icon); }
    .upload-student-tab-container .status[data-type="left"] .icon {
      background: var(--status-left-icon); }
    .upload-student-tab-container .status span {
      font-weight: bold;
      font-size: 12px; }
  .upload-student-tab-container .total-data {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-left: auto; }
    .upload-student-tab-container .total-data svg {
      stroke: #111; }
    .upload-student-tab-container .total-data p {
      font-weight: bold;
      font-size: 15px; }
  .upload-student-tab-container .upload-student-tab-container .ant-spin {
    width: unset; }
  .upload-student-tab-container [data-type="upload"] .icon {
    background: var(--status-upload-icon); }
  .upload-student-tab-container [data-type="error"] .icon {
    background: var(--status-error-icon); }
  .upload-student-tab-container [data-type="left"] .icon {
    background: var(--status-left-icon); }
  .upload-student-tab-container .student-data-status {
    display: flex;
    gap: 0.5em;
    align-items: center; }
    .upload-student-tab-container .student-data-status .icon {
      width: 10px;
      height: 10px;
      border-radius: 2px; }
    .upload-student-tab-container .student-data-status[data-type="upload"] p {
      color: var(--status-upload-text); }
    .upload-student-tab-container .student-data-status[data-type="upload"] .icon {
      background: var(--status-upload-icon); }
    .upload-student-tab-container .student-data-status[data-type="error"] p {
      color: var(--status-error-text); }
    .upload-student-tab-container .student-data-status[data-type="error"] .icon {
      background: var(--status-error-icon); }
    .upload-student-tab-container .student-data-status[data-type="left"] p {
      color: var(--status-left-text); }
    .upload-student-tab-container .student-data-status[data-type="left"] .icon {
      background: var(--status-left-icon); }
  .upload-student-tab-container .tab-active {
    border: none;
    background-color: #f0f1f7; }
  .upload-student-tab-container .student-data-error {
    color: #e84b29; }
  .upload-student-tab-container .editable-cell-value-wrap {
    border: 1px solid transparent;
    padding: 0.5em; }
  .upload-student-tab-container tbody > tr:hover > td {
    background: unset !important; }
  .upload-student-tab-container .status:nth-child(1) {
    margin-right: 16px; }
  .upload-student-tab-container .ant-header-title {
    padding-left: 7px;
    display: flex;
    align-items: center;
    gap: 0.4em; }
    .upload-student-tab-container .ant-header-title svg {
      display: block;
      cursor: pointer; }
      .upload-student-tab-container .ant-header-title svg.ant-popover-open {
        color: #1690ff;
        stroke: #1690ff;
        transition: 0.2s; }
  .upload-student-tab-container > * + * {
    margin-top: 1.6em; }
  .upload-student-tab-container p {
    margin: 0; }
  .upload-student-tab-container .ant-spin {
    width: 100%; }
  .upload-student-tab-container .error {
    color: red;
    font-size: 12px;
    letter-spacing: 1px; }
  .upload-student-tab-container .file-header {
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    padding: 0.5em;
    display: flex;
    align-items: center;
    color: #6d747a;
    font-weight: 600;
    font-size: 15px;
    padding-inline: 16px;
    gap: 0.5em; }
  .upload-student-tab-container .file-info {
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: #3a3f43;
    font-weight: 500; }
  .upload-student-tab-container .ant-table-small {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-top: 1px solid transparent; }
  .upload-student-tab-container table .ant-table-thead > tr > th {
    font-weight: 600; }
  .upload-student-tab-container table .table-cell-input {
    resize: none;
    box-sizing: border-box;
    color: #111;
    outline: 1px solid transparent; }
    .upload-student-tab-container table .table-cell-input[data-type="lowercase"] {
      text-transform: lowercase; }
    .upload-student-tab-container table .table-cell-input[data-type="uppercase"] {
      text-transform: uppercase; }
    .upload-student-tab-container table .table-cell-input[data-type="capitalize"] {
      text-transform: capitalize; }
  .upload-student-tab-container table .has-success .table-cell-input {
    transition: none;
    border: 1px solid transparent;
    outline: 1px solid transparent; }
    .upload-student-tab-container table .has-success .table-cell-input:focus {
      box-shadow: none;
      outline: 0;
      border: 1px solid #3fa9ff; }
    .upload-student-tab-container table .has-success .table-cell-input[data-editing="false"]:hover {
      outline: 1px solid rgba(17, 17, 17, 0.088); }
    .upload-student-tab-container table .has-success .table-cell-input[disabled] {
      background: none; }
      .upload-student-tab-container table .has-success .table-cell-input[disabled]:hover {
        border: 1px solid transparent;
        outline: 1px solid transparent; }
      .upload-student-tab-container table .has-success .table-cell-input[disabled]:focus {
        box-shadow: none;
        outline: 0;
        outline: 1px solid transparent; }
  .upload-student-tab-container .ant-table-wrapper {
    letter-spacing: 0 !important; }

.ant-select-dropdown {
  letter-spacing: 0px; }

.ant-message {
  font-weight: 500;
  letter-spacing: initial; }
  .ant-message .ant-message-error {
    color: #b91d1d !important; }
  .ant-message .ant-message-success {
    color: #48825a !important; }

.upload-students-table-popover .ant-popover-inner {
  padding: 0;
  font-weight: 500;
  letter-spacing: 0;
  max-width: 50ch; }
  .upload-students-table-popover .ant-popover-inner .ant-popover-inner-content {
    padding: 12px; }
  .upload-students-table-popover .ant-popover-inner ul {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    list-style: none;
    font-size: 13px;
    padding: 0;
    margin: 0; }
  .upload-students-table-popover .ant-popover-inner li::before {
    content: "✓";
    margin-right: 10px;
    color: green; }

.upload-student-modal .turncate {
  width: 23ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.upload-student-modal * {
  letter-spacing: 0px !important; }

.upload-student-modal .ant-modal-body {
  letter-spacing: 1px;
  padding-bottom: 15px;
  padding: 0;
  padding-bottom: 25px; }

.upload-student-modal .ant-modal-content {
  --modal-bg: #fafbfe;
  --border-clr: #eceef1;
  --export-clr: #616b7e;
  --select-clr: #515458;
  --padding: 1em;
  --export-bg: #fafbfd;
  --arrow-circle-icon-bg: #fff;
  --primary-btn-bg: #638ae9;
  border-radius: 8px;
  background-color: var(--modal-bg) !important;
  padding: 1.5em; }
  .upload-student-modal .ant-modal-content p {
    margin: 0; }
  .upload-student-modal .ant-modal-content .ant-btn-default {
    background: transparent;
    font-weight: 600; }
  .upload-student-modal .ant-modal-content .ant-btn:nth-child(1) {
    background-color: transparent; }
  .upload-student-modal .ant-modal-content .ant-btn-primary {
    border: none;
    font-weight: bold; }
  .upload-student-modal .ant-modal-content .ant-select-arrow {
    color: #545c69; }
  .upload-student-modal .ant-modal-content .ant-select-selection {
    position: relative;
    height: 100% !important;
    display: flex;
    align-items: center;
    border: 0 !important;
    border-top: 1px solid var(--border-clr) !important;
    border-left: 0px dotted var(--border-clr) !important;
    padding-left: var(--padding) !important;
    color: var(--select-clr) !important;
    font-weight: 600; }
    .upload-student-modal .ant-modal-content .ant-select-selection:focus {
      border: unset;
      box-shadow: unset; }
  .upload-student-modal .ant-modal-content .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px; }
  .upload-student-modal .ant-modal-content .header {
    color: #111;
    font-size: 16px;
    font-weight: bold; }
  .upload-student-modal .ant-modal-content .select-headers {
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .upload-student-modal .ant-modal-content .export-headers,
  .upload-student-modal .ant-modal-content .choose-headers {
    position: relative;
    border: 1px solid var(--border-clr);
    border-top: none; }
  .upload-student-modal .ant-modal-content .choose-headers {
    border-left: none; }
  .upload-student-modal .ant-modal-content .export-headers > div {
    padding-left: var(--padding);
    color: var(--export-clr);
    font-weight: 500;
    font-size: 14px;
    background-color: var(--export-bg);
    border-top: 1px solid var(--border-clr);
    display: flex;
    align-items: center; }
  .upload-student-modal .ant-modal-content .export-headers,
  .upload-student-modal .ant-modal-content .choose-headers {
    display: grid;
    grid-auto-rows: 42px; }
  .upload-student-modal .ant-modal-content .ant-modal-footer {
    border-top: none;
    padding: 0; }
  .upload-student-modal .ant-modal-content .error-text {
    color: #f87171;
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px; }
