/** Antd Custom Select Styles */
.custom-selectOption:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background: #FAF7FF !important;
}
.custom-selectOption.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background: #FAF7FF !important;
}

/** FullCalendar Custom Button Style */
.fc-sessionTypeFilter-button, .fc-datePicker-button{
    background: transparent !important;
}

.fc-verticalFilter-button, .fc-datePicker-button{
    background: transparent !important;
}

.fc-paySlab-button, .fc-datePicker-button{
    background: transparent !important;
}

.fc-stages-button, .fc-datePicker-button{
    background: transparent !important;
}

.fc-sideView-button, .fc-datePicker-button{
    background: transparent !important;
    cursor: auto !important;
    right: 3%;
    position: absolute;
    top: 9.7%;
    z-index: 100;
    width: 45%;
    height: fit-content;
    min-height: fit-content;
    border-radius: 0 !important;
    cursor: auto;
    margin: 0;
}

.fc-empty-button, .fc-datePicker-button{
    display: none !important;
}

.styledTooltip-demandEvent {
    z-index: 9999;
    .ant-tooltip-inner {
        font-family: 'Inter';
        font-weight: 500;
        letter-spacing: 0px;
        background: white;
        color: black;
        border-radius: 8px;
    }
    .ant-tooltip-arrow::before {
        bottom: 6px;
        width: 15px;
        height: 15px;
        background-color: white;
    }
}