.previewComponent{
    position: 'relative';
    max-height: 150px;
    width: 100%;
    overflow-y: scroll;
    background: inherit;

    & .tox-tinymce {
        box-shadow: none;
        border: none;
        background-color: transparent;
    }

    & iframe {
        background: inherit !important;
        background-color: inherit !important;
    }

    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #00ADE6;
    }
}

.switch-editor-btn {
    position: absolute;
    top: 6px;
    right: 0;
    cursor: pointer;
    z-index: 99;
    padding: 6px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    transition: all .5s;
    transform: scale(.9);
    background-color: #EEE;
    &:hover {
        transform: scale(1);
        background-color: #DDD;
    }
    &:active {
        transform: scale(.8);
        background-color: #DDD;
    }
}