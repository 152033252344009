@import '../../../scss/size.scss';

.CardAvatar {
    position: relative;
    top: -30px;
    left: 168px;
}

.CardAvatar p {
    position: inherit;
    top: -21px;
    left: 30px;
    font-weight: bold;
    font-size: 12px;
}

.ant-card-body {
    background: white;
    padding-bottom: 0px;
}

.trending {
    background-color: #d3e7ee;
    width: 30%;
    height: 1%;
    border-radius: 8px;
    font-size: 9px;
    padding: 2px 0 2px 6px;
    color: rgb(16, 50, 130);
    position: relative;
    left: -15px;
    top: -10px;
}

.desc {
    z-index: -1;
    height: 60px;
}

.desc h1{
    position: relative;
    left: -15px;
    top: -35px;
    font-size: 13.5px;
    font-weight: bold;
}

.calender {
    position: relative;
    top: -35px;
    left: -15px;
}

.calender p {
    position: relative;
    top: -18.5px;
    left: 17px;
    font-size: 12px;
    font-weight: bold;
}

.clock {
    position: relative;
    top: -74px;
    left: 80px;
}

.clock p {
    position: relative;
    top: -19px;
    left: 19px;
    font-size: 12px;
    font-weight: bold;
}

.tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    top: -40px;
    left: -15px;
}

.tag {
    background-color: #ffffff;
    border: 0.002px solid #bbbbbb;
    width: auto;
    height: 1%;
    border-radius: 2px;
    font-size: 9px;
    padding: 2px 0 2px 6px;
    color: #989797;
    padding-right: 5px;
    margin-right: 5px;
}