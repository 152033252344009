.demand-supply-className{
    color: white;
    background-color:#8C61CB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    color: white;
    & .ant-notification-notice-icon{
        margin: 0;
        margin-right: 4px;
        padding: 10px;
        display: flex;
        background-color: white;
        border-radius: 10px;
    }
    & .ant-notification-notice-message{
        color: white;
    }
    & .ant-notification-notice-close{
        color: white;
        &:hover{
            color: white;
        }
    }
}

.demand-supply-withIcon-className{
    color: white;
    background-color:#8C61CB;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    color: white;
    & .ant-notification-notice-icon{
        margin: 0;
        margin-right: 4px;
        padding: 0;
        display: flex;
        background-color: white;
        border-radius: 30px;
    }
    & .ant-notification-notice-message{
        color: white;
    }
    & .ant-notification-notice-close{
        color: white;
        &:hover{
            color: white;
        }
    }
}