.reportDate{
color:#9E9E9E;
font-size: 10px;
margin-bottom: 15px;
}
.paymentStatus{
    background-color: #01AA93;
    color: white;
    font-size: 8px;
    display: inline-block;
    padding: 2px 4px;
    border-radius: 5px;
}
.batchNameText{
    color: #757575;
    font-size: 10px;
}
.DescriptionTitle{
    color: #757575;
    font-size: 10px;
}
.batchDetailsWrapper, .studentDetailsWrapper{
display: flex;
align-self:center;
}
.noAttemptsDetailDesc>p{
    color: #757575;
    font-size: 12px;
}
.batchAndStudentDetailsDesc{
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;
}
.programmingBasisDesc{
    font-size: 12px;
    margin-bottom: 12px;
}
.studentMethod{
    display: flex;
    align-items: center;
    width: 8%;
    justify-content: space-between;
    margin-bottom: 20px;
    min-width: 110px;
}
.reportsContainer{
    width: 100%;
    padding: 15px;
}
.courseProgressBarInReportContainer{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.courseProgressBar{
    position: relative;
    height: 6px;
    width: 77%;
}
.courseProgressBarInner{
    position: absolute;
    left: 0;
    top: 0;
    width: 80%;
    background-color: #01AA93;
    height: 100%;
    border-radius: 20px;
}
.reportsWrapper{
    display: flex;
    width: 100%;
    align-items: center;
}
.reportDetailsDesc{
    margin-left: 1.5%;
    width: 100%;
}
.methodDenotion{
    background-color: #FAAD14;
    border-radius: 5px;
    width: 18px;
    height: 18px;
}
.reportCourseTitle{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
}
.batchDescriptionText{
    margin-left: 12px;
}
.batchAndStudentDetailsDesc{
    margin-top: 15px;
    margin-bottom: 12px;
}
.batchNameText{
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    font-size: 12px;
}
.DescriptionTitle{
    color: #757575;
    font-size: 12px;
}
.printReportBtn{
    width: 100%;
    background-color: #01AA93;
    border: none;
    outline: none;
    color: white;
    border-radius: 8px;
    padding: 6px 0px 6px 0px;
    margin-top: 30px;
    font-size: 12px;
}
.studentPresentStatus, .studentAbsentStatus, .studentNotApplicableStatus{
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}
.classesCalendarContainer{
    width: 100%;
}
.classStudentStatus{
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 36%;
    justify-content: space-around;
    margin-bottom: 3vh;
}
.classStudentStatus p{
    font-size: 10px;
    margin-left: 5px;
}
.classStatusIndicatorContainer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
}
.classStatusIndicator{
    width: 27%;
    cursor: pointer;
    max-width: 30px;
    height: 30px;    
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}
.activeSession{
    width: 27%;
    cursor: pointer;
    max-width: 30px;
    height: 30px;    
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #01AA93;
}
.classStatusIndicator p{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #01AA93;
    font-size: 12px;
}
.classesCalendarContainer th{
    width: 17%;
    background-color: white;
    color: #333333;
    font-size: 12px;
    text-align: center;
}
.mentorProfilePic{
    width: 22px;
    height: 22px;
    border-radius: 50%;
}
.classesCalendarContainer td{
background-color: white;
}
.weeksContainer{
    color: #333333;
    font-size: 12px;
    font-weight: bold;
}
.nextYearText{
    margin-block: auto;
    background-color: white;
}
.nextYearTableContainer th{
    background-color: white;
}
.classCalendarReportWrapper{
    display: flex;
    max-width: 100%;
    overflow-x: scroll;
    height: 280px;
}
.weeksContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 8%;
    height: 80%;
    margin-top: auto;
    min-width: 100px;
}
.classCalendarReportWrapper table{
    width: 90%;
}
.classesCalendarContainer{
    margin-top: 4vh;
}
.assignedMentorRatingText{
    color: #FAAD14;
}
.AssignedMentorRating{
    display: flex;
    align-items: center;
}
.mentorDetails{
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
}
.classDetailsInReportTopSection{
    display: flex;
}
.classDetailsInReportContainer{
    width: 39%;
    padding: 8px;
    padding-left: 20px;
    border-radius: 8px;
    margin-top: 4vh;
}
.classDetailsInReportTimeDesc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #757575;
    font-size: 10px;
    max-width: 75%;
}
.classDetailsInReportTopSectionDesc{
    width: 100%;
}
.classDetailsText{
    color: #333333;
    margin-bottom: 2.5vh;
    font-size: 12px;
    font-weight: bold;
}
.classDetailsInReportTopSection{
    width: 100%;
}
.classDetailsInReportTopSectionDesc{
    margin-left: 5%;
}
.classDetailsInReportProgressDetails{
    display: flex;
    width: 95%;
    justify-content: space-between;
}
.classDetailsInReportProgressBarOuter{
    width: 95%;
    background-color: #eeeeee;
    height: 5px;
    position: relative;
    border-radius: 20px;
    margin-bottom: 3vh;
    margin-top: 1vh;
}
.classDetailsInReportProgressBarInner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #01AA93;
    border-radius: 20px;
}
.classDetailsInReportProgressDetails {
    margin-top: 15px;
}
.classDetailsInReportProgressDetails p:first-child{
font-size: 12px;
color: #757575;
}
.mentorAssignedText{
    font-size: 12px;
    color: #757575;
    margin-bottom: 2vh;
}
.mentorAssignedName{
    transform: translateY(2px);
    margin-right: 15px;
    margin-left: 10px;
}
.AssignedMentorRating{
    background-color: #FEF6E6;
    padding: 2px;
}
.assignedMentorRatingText{
    font-size: 12px;
}
.classDetailsInReportContainer{
    background-color: white;
    border-radius: 8px;
    padding-bottom: 15px;
}
.classDetailsInReportFlexContainer{
    width: 100%;  
}
.attendanceCardText{
    font-size: 12px;
    color: #333333;
    font-weight: bold;
}
.attendanceDetailsInReportTimeDesc{
    display: flex;
    align-items: center;
}
.attendanceDetailsInReportTimeDesc>p{
    font-size: 12px;
    color: #757575;
    margin-left: 6px;
}
.attendanceDetailsJoinDesc, .attendanceDetailsLeaveDesc{
    display: flex;
    align-items: center;
    max-width: 100%;    
}
.attendanceDetailsJoinDesc p:first-child, .attendanceDetailsLeaveDesc p:first-child{
    color: #757575;
    font-size: 12px;
}
.classDetailsInReportFlexContainer{
    display: flex;
}
.attendanceDetailsJoinDesc p:last-child, .attendanceDetailsLeaveDesc p:last-child{
    color: #333333;
    font-size: 14px;
    margin-left: 5px;
}
.attendanceAndHomworkCardContainer{
    margin-top: 4vh;
}
.attendanceDetailsInReportTimeDesc{
    font-size: 12px;
}
.pieChart{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(white 0deg 45deg,#01AA93 45deg 360deg);
}
.topicThumbnailImage{
    width: 52px;
    height: 62px;
}
.pieChart h3{
    color: #333333;
}
.attendanceCardDescAndPieChartContainer{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.pieChartContainer{
    background-color: white;
}
.attendanceAndHomworkCardContainer{
    width: 61%;
}
.attendanceCardContainer{
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    margin-left: 5%;
    width: 52%;
}
.attendanceDetailsJoinDesc, .attendanceDetailsLeaveDesc, .attendanceDetailsInReportTimeDesc{
    margin-top: 5px;
    margin-bottom: 5px;
}
.attendanceDetailsJoinDesc, .attendanceDetailsLeaveDesc{
    justify-content: space-between;
}

.attendanceDetailsLeaveDesc{
    margin-bottom: 15px;
}
.CorrectAnswersText, .totalQuestionsText, .inorrectAnswersText, .unansweredQuestionsText{
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 1vh;
}
.attemptsDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 55%;
    margin-bottom: 1.5vh;
}

.attemptsDetails>p{
    font-size: 12px;
    color: #757575;
    margin-left: 6px;
}
.attemptsDetailDesc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    min-width: 185px;
}
.attemptsDetailDesc p:first-child{
    color: #757575;
    font-size: 12px;
    }

.attemptsDetailDesc p:last-child{
color: #333333;
font-size: 14px;
}
.homeworkCardText{
    color: #333333;
    font-size: 12px;
    margin-bottom: 1.5vh;
    font-weight: bold;
}
.homeworkCardContainer{
    display: flex;
    margin-top: 2vh;
    padding: 15px;
    background-color: white;
    width: 90%;
    justify-content: space-between;
}
.totalQuestionsProgressBar, .CorrectAnswersProgressBar, .inorrectAnswersProgressBar, .unansweredQuestionsProgressBar{
    height: 8px;
    width: 100%;
    position: relative;
    background-color: #eeeeee;
    border-radius: 25px;
    margin-bottom: 1.8vh;
}
.totalQuestionsProgressBarInner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2593E2;
    border-radius: 25px;
}
.CorrectAnswersProgressBarInner{
    position: absolute;
    left: 0;
    top: 0;
    width: 70%;
    height: 100%;
    background-color: #01AA93;
    border-radius: 25px;
}
.inorrectAnswersProgressBarInner{
    position: absolute;
    left: 0;
    top: 0;
    width: 20%;
    height: 100%;
    background-color: #FF5744;
    border-radius: 25px;
}
.unansweredQuestionsProgressBarInner{
    position: absolute;
    left: 0;
    top: 0;
    width: 10%;
    height: 100%;
    background-color: #A8A7A7;
    border-radius: 25px;
}
.courseThumbnail{
    width: 173px;
    height: 165px;
    padding: 10px;
    background-color: #eeeeee;
    border-radius: 8px;
}
.homeworkCardProgressContainer p{
    font-size: 12px;
    color: #757575;
}
.homeworkCardProgressContainer{
    width: 50%;
}
.homeworkCardWrapper{
    width: 47%;
    display: flex;
    flex-direction: column;
}
.viewMoreBtn{
    border: none;
    outline: none;
    background-color: #01AA93;
    border-radius: 8px;
    width: 40%;
    min-width: 100px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    font-size: 12px;
    margin-top: auto;
    margin-bottom: 10px;
}
.courseProgressBarInReportContainer{
    margin-top: 15px;
}
.classesInReportCalendarContainer th{
    text-align: left;
    min-width: 150px;
}
.classesInReportCalendarContainer td{
    text-align: left;
}
.classesInReportCalendarContainer{
    overflow-x: scroll;
    max-width: 100vh;
}
.nextYearTableContainer th{
    text-align: left; 
}
.nextYearTableContainer td{
    text-align: left; 
    min-width: 150px;
}
.absentClassStatusIndicator{
    width: 27%;
    cursor: pointer;
    max-width: 30px;
    height: 30px;    
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}
.activeAbsentClassDenoter{
    width: 27%;
    cursor: pointer;
    max-width: 30px;
    height: 30px;    
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #01AA93;
    color: #FF5744;
}
.absentClassDenoter{
    width: 27%;
    cursor: pointer;
    max-width: 30px;
    height: 30px;    
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #FF5744;
    color: #FF5744;
}
.classDenoter{
    background-color: #E6F7F5;
    padding-block: 6px;
    padding-inline: 9px;
}
.activeClassDenoter{
    background-color: #E6F7F5;
    padding-block: 5px;
    padding-inline: 8px;
    border: 2px solid #01AA93;
}
@media screen and (max-width: 1100px){
    .classesInReportCalendarContainer td,.classesInReportCalendarContainer th{
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media screen and (max-width: 876px){
    .classStatusIndicator{
        width: 28%;
        height: 30px;
    }
    .classStatusIndicator p{
        font-size: 10px;
    }
    .weeksContainer{
        width: 7%;
        margin-top: 55px;
    }
}
@media screen and (max-width: 820px){
    .batchAndStudentDetailsDesc{
        width: 42%;
        min-width: 172px;
    }
}
@media screen and (max-width: 995px){
    .classStudentStatus{
        width: 40%;
        min-width: 272px;
    }
}
@media screen and (max-width: 1100px){
    .classDetailsInReportFlexContainer{
        flex-direction: column;
        align-items: center;
    }
    .classDetailsInReportContainer{
        width: 50%;
        min-width: 411px;
    }
    .classDetailsText{
        text-align: center;
    }
    .attendanceAndHomworkCardContainer{
        display: flex;
        width: 100%;
    }
    .attemptsDetails{
        width: 70%;
        align-items: center;
        min-width: 141px;
    }
    .classDetailsInReportTimeDesc{
        width: 90%;
    }
}
@media screen and (max-width: 1021px){
.attendanceCardDescAndPieChartContainer{
    flex-direction: column;
}
.attendanceCardText{
    text-align: center;
    margin-bottom: 1.5vh;
}
}
@media screen and (max-width: 931px){
    .homeworkCardContainer{
        flex-direction: column;
        position: relative;
        padding-bottom: 50px;
    }
    .viewMoreBtn{
        position: absolute;
        bottom: 10px;
    }
    .homeworkCardWrapper{
        width: 90%;
    }
    .homeworkCardProgressContainer{
        width: 90%;
    }
    .attemptsDetails{
        width: 100%;
        justify-content: center;
    }
    .homeworkCardText{
        text-align: center;
    }
    .homeworkCardWrapper{
        margin-bottom: 2vh;
    }
}