.courseDate{
    color: black;
    font-weight: 600;
    word-spacing: -2px;    
}
.singleCourseCardComponent h3{
    color: black;
}
.singleCourseCardComponent{
    color:#757575;
    width: 49%;    
    font-family: 'Inter', sans-serif;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    padding-bottom: 40px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 15px;
}
.expandedSingleCourseCardComponent{
    color:#757575;
    width: 100%;
    font-family: 'Inter', sans-serif;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    position: static;
    transform: translate(0,0);
    padding-bottom: 30px; 
    transition: all 0.3s ease-in-out;
}
.sessionsDetails p{
font-size: 10px;
}
.sessionsDetails p:last-child{
color: black;
font-weight: bold;
}
.sessionsDetails{
    display: flex;
    align-items: center;
    width: 40%;
    max-width: 130px;
    justify-content: space-between;
}
.courseProgressDetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.courseProgressDetails p{
    font-size: 10px;
}
.courseProgressDetails p:last-child{
color: black;
font-weight: bold;
}
.singleCourseCardWrapper{
    display: flex;
    width: 100%;

}
.courseProgressBarTrack{
    position: relative;
    background-color: #EEEEEE;
    height:5px;
    width: 70%;
}
.progress{
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: #01AA93;
    border-radius: 20px;
}
.courseDetailsAndStatusContainer{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 80%;
}
.courseDetailsAndStatusContainer>p{
    font-size: 12px;
}
.courseDetailsAndStatusContainer p{
    word-spacing: -1.5px;
    margin-top:1.2%;
    margin-bottom: 1.2%;
}
.courseDetailsAndStatusContainer h3{
    font-size: 20px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}
.imageContainer{
    padding: 3px;
    border-radius: 16px;
}
.batchName{
    text-transform: uppercase;
}
.topicThumbnailImage{
    width: 22px;
    height: 26px;
    margin-right: 10px;
}
.classStatus{
    background-color: #E7FFFC;
    color: #01AA93;
    font-size: 10px;
    display: inline-block;
    margin-bottom: 2%;
    word-spacing: -2px;
}
.singleClassCardWrapper{
    position: relative;
}
.expandClassesBtn{
    border: none;
    outline: none;
    color: #01AA93;    
    cursor: pointer;
    font-size: 12px;
    background-color: transparent;
    word-spacing: -1.5px;
}
.classAndCourseSeperator{
    width: 90%;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 3vh;
    border: 1px solid #EEEEEE;
}
.singleClassCardWrapper{
    display: flex;
}
.classImageContainer{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.classDetailsAndStatusContainer{
    margin-left: 5%;
}
.classImg{
    border-radius: 8px;
}
.batchDetails{
    display: flex;
    align-items: center;
}
.batchDetails p{
font-size: 10px;
margin-left: 10px;
}

.batchName{
    color: black;
    font-size: 12px;
}
.expandedSingleCourseCardSessionProgressBarOuter{
    width: 100%;
    height: 8px;
    margin-top: 10px;
    background-color: #EEEEEE;
    border-radius: 10px;
}
.expandedSingleCourseCardSessionDetails{
    margin-top: 12px;
}
.expandedSingleCourseCardSessionDetailsText{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.expandedSingleCourseCardSessionProgressBarInner{
    width: 22%;
    height: 6px;
    background-color:#01AA93;
    border-radius: 10px;
}
.sessionsCompleteNumbers{
    display: flex;
    align-items: center;
}
.sessionsCompleteNumberDetails{
    color: #333333;
    margin-left: 5px;
}
.expandedComponentSessionDetailsTableContainer{
    width: 100%;
    margin-top: 20px;
    opacity: 1;
    margin-bottom: 20px;
}
.expandedComponentSessionDetailsTableContainer th{
 width: 20%;
 text-align: center;
 background-color:#F5F5F5;
 font-weight: 500;
}
.expandedComponentSessionDetailsTableContainer td{
    font-weight: 500;
    font-size: 14px;
    background-color: transparent;
    text-align: center;
}
.expandedComponentSessionDetailsTableContainer td h4{
    min-width: 70%;
}
.collapsedSingleCourseCardComponent{
    width: 100%;
    opacity: 0;
    z-index: -5;
     position: absolute; 
    height: 0px;
    transform: translateY(-100%);
    transition: all 0.3s linear;
}
.expandedSingleCourseCardTableComponent{
    width: 100%;
    transform: translateY(0) scaleY(1);
    transition: all 0.3s linear;
}
.hiddenExpandedComponentSessionDetailsTableContainer{
    height: 0px;
    width: 0%;
    position: absolute;
    z-index: -5;
    background-color: red;
}
.sessionsCompleteNumbers p:first-child{
    color: #808080;
    font-size: 14px;
}
.sessionsCompleteNumberDetails{
    color:#212121;
    font-size: 14px;
}
.expandedCardSessionProgressPercentageText{
    color: #333333;
    font-size: 14px;
}
.viewReportDetailsAndExpandBtnContainer{
    display: flex;
    align-items: center;
    position: absolute;
    right: 1%;
    bottom: -50%;
}
.viewReportDetailsBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    outline: none;
    background-color: #01AA93;
    padding-block: 5px;    
    padding-inline: 7px;
    font-size: 12px;
    border-radius: 8px;
    margin-left: auto;
    cursor: pointer;
    margin-left: 5px;
}
@media screen and (max-width: 885px){
    .singleCourseCardComponent{
        width: 80%;
        margin-top: 15px;
    }
    .sessionsDetails{
        width: 50%;
    }
    .batchDetails{
        width: 45%;
    }
    .weeklyDate{
        font-size: 11px !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;        
    }
    .sessionsDetails{
        width: 90%;
    }
    .courseProgressBarTrack{
        width: 40%;
    }
}
.courseThumbnailImage{
    height: 83px;
    width: 87px;
}
@media screen and (max-width: 585px){
    .singleCourseCardComponent{
        width: 95%;
    }
    .batchDetails{
        width: 50%;
    }
}
@media screen and (max-width: 450px){
 .batchDetails{
     width: 60%;
 }   
}

.databaseIcon{
    margin-right: 5px;
}

